import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import * as _moment from 'moment';
const moment = _moment;
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { FinancialPixService } from '@gipi-financial/pix/services/pix.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { GIPIAbstractComponent, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { PixVoucherPdfComponent } from '../pix-voucher-pdf/pix-voucher-pdf.component';
export class PixDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, _viewContainerRef, _componentFactoryResolver, _changeDetectorRef, _companyService, _authenticationService, data, dialogRef) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._viewContainerRef = _viewContainerRef;
        this._componentFactoryResolver = _componentFactoryResolver;
        this._changeDetectorRef = _changeDetectorRef;
        this._companyService = _companyService;
        this._authenticationService = _authenticationService;
        this.data = data;
        this.dialogRef = dialogRef;
        this._destroyed = false;
        this._expirationInSeconds = 300; // 5 Minutos = 300 Segundos
        this.firstMinute = 0;
        this.secondMinute = 0;
        this.firstSecond = 0;
        this.secondSecond = 0;
        this.pixReturn = null;
        this.expirationDate$ = new BehaviorSubject('');
        this.timeExpired$ = new BehaviorSubject(false);
        this.pixConfirmed$ = new BehaviorSubject(false);
    }
    get infoTitleStatus$() {
        if (ObjectUtil.isNull(this.pixReturn)) {
            return of('QR Code inválido');
        }
        if (StringUtil.isEmpty(this.pixReturn.qrCode) ||
            (this.pixReturn.status === 'REMOVED_BY_PSP') ||
            (this.pixReturn.status === 'REMOVED_BY_USER_RECEIVER')) {
            return of('QR Code inválido');
        }
        if (this.timeExpired$.getValue()) {
            return of('QR Code expirado');
        }
        if (this.pixConfirmed$.getValue()) {
            return of('Pagamento aprovado');
        }
    }
    get infoDescriptionStatus$() {
        if (ObjectUtil.isNull(this.pixReturn)) {
            return of('Ocorreu um erro ao gerar o QR Code.');
        }
        if (StringUtil.isEmpty(this.pixReturn.qrCode) ||
            (this.pixReturn.status === 'REMOVED_BY_PSP') ||
            (this.pixReturn.status === 'REMOVED_BY_USER_RECEIVER')) {
            return of('Ocorreu um erro ao gerar o QR Code.');
        }
        if (this.timeExpired$.getValue()) {
            return of('O tempo para leitura expirou. Atualize para gerar um novo.');
        }
        if (this.pixConfirmed$.getValue()) {
            return of('O pagamento foi realizado com sucesso.');
        }
    }
    ngOnInit() {
        super.ngOnInit();
    }
    ngOnDestroy() {
        this._destroyed = true;
        this.expirationDate$.complete();
        this.timeExpired$.complete();
        this.pixConfirmed$.complete();
        if (this._intervalTimer) {
            clearInterval(this._intervalTimer);
        }
        if (this._intervalPixCompleted) {
            clearInterval(this._intervalPixCompleted);
        }
    }
    onInitForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (ObjectUtil.isNull(this.data)) {
                    return;
                }
                this.loading = true;
                const pixReturn = yield this.service.generate(this.data.bankAccountId, this.data.value).toPromise().catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                    return null;
                });
                if (!ObjectUtil.isNull(pixReturn)) {
                    this.pixReturn = ObjectUtil.clone(pixReturn);
                    if (pixReturn.pixType === 'DYNAMIC') {
                        this._expirationInSeconds = pixReturn.expirationInSeconds || 300; // 5 Minutos = 300 Segundos
                        const expirationDate = moment().add(this.pixReturn.expirationInSeconds || 300, 'seconds');
                        this.expirationDate$.next(expirationDate.format('DD/MM/YYYY HH:mm:ss'));
                        // Ativa o countdown expiration time
                        this._updateDisplayExpirationTime();
                        this._intervalTimer = setInterval(() => this._countdownExpirationTime(), 1000);
                        // Ativa o timer para validar se o pix foi pago
                        this._intervalPixCompleted = setInterval(() => this._checkPixIsCompleted(), 30000); // 30000 Milissegundos = 30 Segundos
                    }
                }
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
                this.handleError(error);
            }
        });
    }
    _updateDisplayExpirationTime() {
        const minutes = Math.floor(this._expirationInSeconds / 60);
        const seconds = this._expirationInSeconds % 60;
        this.firstMinute = Math.floor(minutes / 10);
        this.secondMinute = (minutes % 10);
        this.firstSecond = Math.floor(seconds / 10);
        this.secondSecond = (seconds % 10);
        if (!this._destroyed) {
            this._changeDetectorRef.detectChanges();
        }
    }
    _countdownExpirationTime() {
        if (this._expirationInSeconds === 0) {
            if (this._intervalTimer) {
                clearInterval(this._intervalTimer);
            }
            this.timeExpired$.next(true);
            return;
        }
        this._expirationInSeconds--;
        this._updateDisplayExpirationTime();
    }
    _checkPixIsCompleted() {
        if (this.timeExpired$.getValue()) {
            if (this._intervalPixCompleted) {
                clearInterval(this._intervalPixCompleted);
            }
            return;
        }
        this.service.consult(this.data.bankAccountId, this.pixReturn.txId).toPromise()
            .then(resp => {
            this.pixConfirmed$.next(!ObjectUtil.isNull(resp) && (resp.status === 'COMPLETED'));
            if (!ObjectUtil.isNull(resp) && (resp.status === 'COMPLETED')) {
                setTimeout(() => this.confirm(), 30000);
            }
        })
            .catch(error => {
            this.loading = false;
            this.addErrorMessage(error);
        });
    }
    _findCompany() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = this._authenticationService.token;
            if (this._authenticationService.isValidToken(token)) {
                const company = yield this._companyService.getOne(token.user.currentCompany).toPromise().catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                    return Promise.resolve(null);
                });
                return Promise.resolve(!ObjectUtil.isNull(company) ? company : null);
            }
            return Promise.resolve(null);
        });
    }
    printQrCode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                const company = yield this._findCompany();
                if (ObjectUtil.isNull(company)) {
                    this.loading = false;
                    this.addErrorMessage('Ocorreu um erro ao buscar os dados para gerar o extrato de pagamento');
                    return;
                }
                const lHTMLStyleElement = document.createElement("style");
                lHTMLStyleElement.textContent = `
            @import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');

            @media print {
                .noPrint {
                    display: none;
                }

                .header,
                .bprint,
                .footer {
                    display: none !important;
                }
            }

            @page :footer {
                display: none;
            }

            @page :header {
                display: none;
            }

            body {
                font-family: "Courier Prime", monospace;
            }

            .receipt {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            h1 {
                font-size: 20px;
                margin-bottom: 5px;
            }

            .info {
                width: 100%;
                font-size: 12px;
                margin-bottom: 10px;
            }

            .divider {
                margin-block: 10px;
                width: 100%;
                border-top: 1px dashed #000;
            }

            .details {
                width: 100%;
                text-align: left;
                font-size: 14px;
            }

            .details strong {
                font-weight: bold;
            }

            .qr-code {
                margin-top: 10px;
            }

            .payment-deadline {
                font-size: 12px;
                margin-top: 10px;
            }
            `;
                this.iFrameRef.nativeElement.contentDocument.head.appendChild(lHTMLStyleElement);
                this._document = this.iFrameRef.nativeElement.contentDocument || this.iFrameRef.nativeElement.CompFrame.contentWindow;
                const componentFactory = this._componentFactoryResolver.resolveComponentFactory(PixVoucherPdfComponent);
                this._componentRefQRCodePDF = this._viewContainerRef.createComponent(componentFactory);
                this._componentRefQRCodePDF.location.nativeElement.id = 'propertyDisplay';
                this._componentRefQRCodePDF.instance.company = ObjectUtil.clone(company);
                this._componentRefQRCodePDF.instance.pixReturn = ObjectUtil.clone(this.pixReturn);
                this._document.body.appendChild(this._componentRefQRCodePDF.location.nativeElement);
                setTimeout(() => {
                    this.loading = false;
                    this.iFrameRef.nativeElement.contentWindow.print();
                    this._componentRefQRCodePDF.destroy();
                    this._document = undefined;
                }, 1000);
            }
            catch (e) {
                this.loading = false;
                this.handleError('Não foi possível gerar o extrato de cobrança. Motivo: ' + e);
            }
        });
    }
    copyQrCode() {
        if (ObjectUtil.isNull(this.pixReturn)) {
            return;
        }
        navigator.clipboard.writeText(this.pixReturn.qrCode)
            .then(_ => this.addSuccessMessage(`Código copiado para a área de transferência!`))
            .catch(error => this.addErrorMessage(error));
    }
    refreshQrCode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                yield this.onInitForm();
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
                this.handleError(error);
            }
        });
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (ObjectUtil.isNull(this.pixReturn)) {
                    return;
                }
                this.loading = true;
                const messages = {
                    'COMPLETED': 'O pagamento via Pix foi concluído com sucesso.',
                    'ENABLED': 'O Pix está ativo e pronto para uso.',
                    'REMOVED_BY_PSP': 'O Pix foi removido pelo provedor de serviços de pagamento.',
                    'REMOVED_BY_USER_RECEIVER': 'O Pix foi removido pelo usuário recebedor.'
                };
                // Se for PIX dinâmico e ainda não estiver confirmado
                if ((this.pixReturn.pixType === 'DYNAMIC') && !this.pixConfirmed$.getValue()) {
                    const pixReturnConsult = yield this.service.consult(this.data.bankAccountId, this.pixReturn.txId).toPromise().catch(error => {
                        this.loading = false;
                        this.addErrorMessage(error);
                        return null;
                    });
                    if (!ObjectUtil.isNull(pixReturnConsult) && (pixReturnConsult.status === 'COMPLETED')) {
                        this.addSuccessMessage(messages[pixReturnConsult.status]);
                        this.close(true);
                        this.loading = false;
                        return;
                    }
                    const confirmMessage = ((!ObjectUtil.isNull(pixReturnConsult) && (pixReturnConsult.status !== 'ENABLED'))
                        ? messages[pixReturnConsult.status]
                        : 'O pagamento ainda não consta na instituição bancária.') + ' Deseja continuar?';
                    const isConfirmed = yield this.baseService.confirmationService.confirm({
                        title: 'Confirmação',
                        message: confirmMessage,
                    });
                    if (isConfirmed) {
                        this.addSuccessMessage(messages['COMPLETED']);
                        this.close(true);
                    }
                    this.loading = false;
                    return;
                }
                // Caso não seja PIX dinâmico ou já esteja confirmado
                this.addSuccessMessage(messages['COMPLETED']);
                this.close(true);
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
                this.handleError(error);
            }
        });
    }
    close(confirmed) {
        // Clicou no voltar, então cancela o pix
        if (!confirmed &&
            !ObjectUtil.isNull(this.pixReturn) &&
            !StringUtil.isEmpty(this.pixReturn.txId) &&
            (this.pixReturn.pixType === 'DYNAMIC')) {
            this.service.cancel(this.data.bankAccountId, this.pixReturn.txId).toPromise().catch(error => {
                this.addErrorMessage(error);
            });
        }
        this.dialogRef.close(confirmed);
    }
}
