<div fxLayout="column"
     fxLayoutAlign="center center">
    <div fxLayout="row"
         fxLayoutAlign="center center"
         fxLayoutGap="150px"
         fxFlex="100vh">
        <itss-card class="confirm-access">
            <div fxLayout="column"
                 fxLayoutAlign="center center">
                <img width="180"
                     src="../../../assets/logo/logo_and_name.png" />
            </div>

            <div fxLayout="column"
                 fxLayoutGap="30px">
                <p *ngIf="!hasAccessControlEnabled">
                    Para confirmar o acesso, digite abaixo o seu nome e a senha que você irar utilizar para acessar o
                    sistema
                </p>
                <p *ngIf="hasAccessControlEnabled">
                    Para confirmar o acesso, digite abaixo a senha que você utiliza para acessar o sistema
                </p>

                <div fxLayout="column"
                     fxLayoutGap="20px">

                    <div fxLayoutAlign="center center"
                         fxLayout="row"
                         fxLayout.lt-md="column"
                         fxLayoutGap="20px">
                        <itss-input label="Nome"
                                    class="input-rounded"
                                    #firstInput
                                    [(ngModel)]="user.name"
                                    [disabled]="hasAccessControlEnabled || loading"
                                    fxFlex="50">
                        </itss-input>

                        <itss-input label="E-mail"
                                    class="input-rounded"
                                    [(ngModel)]="user.username"
                                    disabled="true"
                                    fxFlex="50">
                        </itss-input>
                    </div>

                    <div fxLayout="column"
                         fxLayoutGap="5px"
                         *ngIf="!hasAccessControlEnabled">
                        <itss-input label="Senha"
                                    class="input-rounded"
                                    #passwordRef
                                    [type]="isViewPassword ? 'text' : 'password'"
                                    [inputTrim]="true"
                                    [disabled]="loading"
                                    [min]="6"
                                    [max]="15"
                                    [maxlength]="15"
                                    [(ngModel)]="password"
                                    fxFlex="100"
                                    (focus)="passwordFocused = true"
                                    (blur)="passwordFocused = false">
                            <ng-template #suffix>
                                <itss-button [svgIcon]="isViewPassword ? 'eye' : 'eye_off'"
                                             color="primary"
                                             type="icon"
                                             [tooltip]="isViewPassword ? 'Ocultar senha' : 'Exibir senha'"
                                             (click)="isViewPassword = !isViewPassword">
                                </itss-button>
                            </ng-template>
                        </itss-input>

                        <gipi-password-requeriments *ngIf="passwordFocused || (password && (password.length > 0))"
                                                    [(ngModel)]="password">
                        </gipi-password-requeriments>
                    </div>

                    <itss-input label="Confirmação senha"
                                class="input-rounded"
                                #passwordConfirmationRef
                                [type]="isViewPasswordConfirmation ? 'text' : 'password'"
                                [disabled]="loading"
                                [(ngModel)]="passwordConfirmation"
                                [min]="6"
                                [max]="15"
                                [maxlength]="15"
                                fxFlex="100"
                                (keydown.enter)="keydownConfirmAccess()">
                        <ng-template #suffix>
                            <itss-button [svgIcon]="isViewPasswordConfirmation ? 'eye' : 'eye_off'"
                                         color="primary"
                                         type="icon"
                                         [tooltip]="isViewPasswordConfirmation ? 'Ocultar senha' : 'Exibir senha'"
                                         (click)="isViewPasswordConfirmation = !isViewPasswordConfirmation">
                            </itss-button>
                        </ng-template>
                    </itss-input>
                </div>

                <div fxLayoutAlign="center center"
                     fxLayout="row"
                     fxLayoutGap="20px">
                    <button class="btn-rounded confirm"
                            aria-label="Confirmar"
                            [disabled]="loading"
                            (click)="save()"
                            fxFlex="25"
                            fxFlex.lt-md="100">
                        Confirmar
                    </button>
                    <button class="btn-rounded cancel"
                            aria-label="Cancelar"
                            (click)="returnToLogin()"
                            fxFlex="25"
                            fxFlex.lt-md="100">
                        Cancelar
                    </button>
                </div>
            </div>
        </itss-card>
    </div>
</div>