import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { GIPIAbstractDTO } from '@gipisistemas/ng-core';

export class FinancialIntegrationBillingInvoiceDTO extends GIPIAbstractDTO {

    /** @Required Conta a receber */
    billInstallment: FinancialBillInstallment;

    /** @Required CPF ou CNPJ */
    cnpj: string;

    /** @Required Data final */
    endDate: Date;

    /** @Required Data inicial */
    startDate: Date;

}
