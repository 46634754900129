import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, takeLast } from 'rxjs/operators';

import { environment } from '@gipi-environment/environment';

import { GeneralIcons, MenuIcons, NewIcons } from '@gipi-assets/assets.enum';
import { FinancialCompanySelectDTO } from '@gipi-financial/company/models/dto/company-select.dto';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { ConfigurationDialogComponent } from '@gipi-financial/configuration/components/configuration-dialog/configuration-dialog.component';
import { FinancialConfigurationService } from '@gipi-financial/configuration/services/configuration.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { OAuthUserService } from '@gipi-financial/user/services/user.service';
import { GlobalFileService } from '@gipi-global/file/services/file.service';
import { GIPINoveltiesDialogComponent } from '@gipi-shared/components/novelties-dialog/novelties-dialog.component';
import { UserInformationDialogComponent } from '@gipi-shared/components/user-information-dialog/user-information-dialog.component';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { ArrayUtil, DialogService, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, MenuDTO, NavService, ObjectUtil, StringUtil, SvgRegisterService, TokenDTO } from '@gipisistemas/ng-core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends GIPIAbstractComponent implements OnInit, AfterViewInit, OnDestroy {

    public companyList: FinancialCompanySelectDTO[] = [];
    public company: FinancialCompanySelectDTO = null;

    public userPhotoSrc: string = '';

    public widgetChat: boolean = false;

    companyFn = (obj: FinancialCompanySelectDTO) => !ObjectUtil.isNull(obj) ? obj.name : '';

    public get isAuthenticated$(): Observable<boolean> {
        const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            this._router.events
                .pipe(
                    takeLast(1),
                    filter(event => (event instanceof NavigationEnd) && !event.url.includes('/oauth'))
                ).subscribe((event: NavigationEnd) => {
                    const urlFinal: string = event['urlAfterRedirects'];
                    const isOAuthRoute: boolean = !StringUtil.isEmpty(urlFinal) && urlFinal.toLowerCase().includes('/oauth');
                    if (!isOAuthRoute) {
                        this._router.navigate(['/oauth/login']);
                    }
                });
            // const isOAuthRoute: boolean = this._router.url.includes('/oauth');
            // if (!isOAuthRoute) {
            //     this._router.navigate(['/oauth/login']);
            // }
            return of(false);
        }

        return of(!ObjectUtil.isNull(token) && this._customAuthenticationService.isValidToken(token));
    }

    public get firstName(): string {
        const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return StringUtil.EMPTY;
        }
        const nameParts: string[] = token.user.name.split(' ');
        const firstName: string = ((nameParts.length > 1) && (nameParts[0].length <= 2)) ? `${nameParts[0]} ${nameParts[1]}` : nameParts[0];
        return (`Olá, ${firstName}` || StringUtil.EMPTY);
    }

    public get username(): string {
        const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return StringUtil.EMPTY;
        }
        return (token.user.name || StringUtil.EMPTY);
    }

    public get email(): string {
        const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return StringUtil.EMPTY;
        }
        return (token.user.username || StringUtil.EMPTY);
    }

    public get lastAccess(): Date {
        const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return null;
        }
        return (token.user.lastAccess || null);
    }

    public get menuList$(): Observable<MenuDTO[]> {
        return this._customAuthenticationService.token$.pipe(map(token => !ObjectUtil.isNull(token) ? this._filterMenuItems(token.user.menuList) : []));
    }

    public get hasNovelties(): boolean {
        // ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"]
        var date = new Date();
        var weekday: number = date.getDay();
        return ((weekday) && (weekday === 3));
    }

    private get _isCompanyGIPI(): boolean {
        const currentCnpjCompany: string = (!ObjectUtil.isNull(this.company) ? this.company.cpfOrCnpj : null);
        const cnpjCompanyGIPI: string = environment.cnpjCompany || null;
        return (
            !StringUtil.isEmpty(cnpjCompanyGIPI) &&
            !StringUtil.isEmpty(currentCnpjCompany) &&
            (cnpjCompanyGIPI === currentCnpjCompany)
        );
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _router: Router,
        private _customAuthenticationService: CustomAuthenticationService,
        private _userService: OAuthUserService,
        private _companyService: FinancialCompanyService,
        private _configurationService: FinancialConfigurationService,
        private _dialogService: DialogService,
        private _navService: NavService,
        private _svgRegisterService: SvgRegisterService,
        private _fileService: GlobalFileService,
        private _sessionStorageService: SessionStorageService<any>,
    ) {
        super(baseService, activatedRoute);
        this._loadScriptHotjar();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._registerSvgs();

        this.isAuthenticated$.subscribe(isAuth => {
            if (isAuth) {
                const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
                if (!ObjectUtil.isNull(token) && this._customAuthenticationService.isValidToken(token)) {
                    this._getUserPhoto();
                    this._getCompany();
                    this._setConfigurationInLocalStorage();
                }
            }
        });

        // this._customAuthenticationService.token$.subscribe((token: TokenDTO<OAuthUser>) => {
        //     if (!ObjectUtil.isNull(token) && this._customAuthenticationService.isValidToken(token)) {
        //         this._getUserPhoto();
        //         this.getNotifications();
        //         this._getCompany();
        //         this._setConfigurationInLocalStorage();
        //     }
        // });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        setTimeout(_ => this._showWidgetChat(), 1000);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public toggleSidenav(): void {
        this._navService.toggle();
    }

    public isOpened(): boolean {
        return this._navService.isOpened;
    }

    public changeCompany(company: FinancialCompanySelectDTO): void {
        try {
            this.loading = true;
            this._customAuthenticationService.changeCompany(company.id).toPromise().then(_ => {
                location.replace('/');
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    public logout(): void {
        this._customAuthenticationService.logout();
        this.clearSessionStorage();
    }

    public openNoveltiesDialog(): void {
        this._dialogService.open({
            componentOrTemplateRef: GIPINoveltiesDialogComponent,
            width: '55%',
            height: '90%',
            panelClass: 'no-scrolls-dialog'
        });
    }

    public async openUserInformationDialog(): Promise<void> {
        try {
            const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
            if (ObjectUtil.isNull(token)) {
                this.addErrorMessage('Ocorreu um erro ao obter as credências para alteração do usuário');
                return;
            }

            const userLogged: OAuthUser = await this._userService.getByUserName(token.user.username).toPromise().catch(error => {
                this.addErrorMessage(error);
                return null;
            });

            if (ObjectUtil.isNull(userLogged)) {
                this.addErrorMessage('Ocorreu um erro ao obter as credências para alteração do usuário');
                return;
            }

            this._dialogService.open({
                componentOrTemplateRef: UserInformationDialogComponent,
                data: ObjectUtil.clone(userLogged),
                width: '50%'
            }).afterClosed().toPromise().then((user: OAuthUser) => {
                if (!ObjectUtil.isNewModel(user)) {
                    userLogged.name = user.name;
                    userLogged.photo = user.photo;
                    this._getUserPhoto();
                }
            });
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    public clearSessionStorage(): void {
        sessionStorage.clear();
    }

    public onMenuItemIntegrationChange(): void {
        this._router.navigate(['financial/integrations']);
    }

    public onMenuItemConfigurationChange(): void {
        this._dialogService.open({
            componentOrTemplateRef: ConfigurationDialogComponent,
            data: null,
            width: '60%',
            height: '65rem'
        });
    }

    private _loadScriptHotjar(): void {
        const hotjarId: number = environment.hotjarId;
        if (environment.production && (hotjarId > 0)) {
            let scriptElement: HTMLScriptElement = document.createElement('script');
            scriptElement.textContent = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `;

            document.getElementsByTagName('head')[0].appendChild(scriptElement);
        }
    }

    private _showWidgetChat(): void {
        const btnWidgetChat: HTMLButtonElement = (document.getElementById('widgetChat') as HTMLButtonElement);
        if (!ObjectUtil.isNull(btnWidgetChat)) {
            btnWidgetChat.style.display = 'flex';
        }
    }

    private _getCompany(): void {
        const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return;
        }

        let count: number = 1;

        this._companyService.findByValue('', 0, 100, new GIPISortModel('person.name', 'ASC')).subscribe(page => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                if ((token.user.companyList.length !== page.content.length) && count <= 2) {
                    this._getCompany();
                    count++;
                } else {
                    this.companyList = ArrayUtil.clone(page.content);
                    const currentCompany: FinancialCompanySelectDTO = page.content.find(company => company.id === token.user.currentCompany);
                    this.company = ObjectUtil.clone(currentCompany);
                }
            }
        }, error => {
            this.loading = false;
            this.addErrorMessage(error);
        });
    }

    private _registerSvgs(): void {
        this._svgRegisterService.registerListSvgIcons(GeneralIcons, '../assets/images/icons');
        this._svgRegisterService.registerListSvgIcons(MenuIcons, '../assets/images/menu');
        this._svgRegisterService.registerListSvgIcons(NewIcons, '../assets/images/new-icons');
    }

    private _filterMenuItems(menuList: MenuDTO[]): MenuDTO[] {
        if (!environment.production) {
            return menuList;
        }

        const hideMenuList: string[] = [
            '/financial/registers/products/products',
            '/financial/registers/promotional-schedules/promotional-scheduling',
        ];

        if (!this._isCompanyGIPI) {
            hideMenuList.push('/financial/notifications/invoice-integration');
        }

        return menuList.reduce((filtered, item) => {
            if (!hideMenuList.includes(item.route)) {
                if (item.menuList && item.menuList.length > 0) {
                    item.menuList = this._filterMenuItems(item.menuList);
                }
                filtered.push(item);
            }
            return filtered;
        }, []);
    }

    private _getUserPhoto(): void {
        try {
            const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
            if (ObjectUtil.isNull(token)) {
                return;
            }

            if (!StringUtil.isEmpty(token.user.photo)) {
                this._fileService.download('authenticator', token.user.photo).subscribe(resp => {
                    const fileReader: FileReader = new FileReader();
                    fileReader.readAsDataURL(resp.body);
                    fileReader.onloadend = () => this.userPhotoSrc = fileReader.result as string;
                    this.loading = false;
                }, error => {
                    throw new Error(error);
                });
            }
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    private _setConfigurationInLocalStorage(): void {
        this._configurationService.findEnabled().subscribe(configuration => this._sessionStorageService.set('configuration', configuration));
    }

}
