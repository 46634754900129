import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractService, GIPIBaseService, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import { catchError, map, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialPixService extends GIPIAbstractService {
    constructor(baseService) {
        super(FinancialPaths.pix, 'v1');
        this.baseService = baseService;
    }
    cancel(bankAccountId, txId, version) {
        if (!UUIDUtil.isValid(bankAccountId)) {
            throw new Error('Id da conta bancária é obrigatório e não foi informado');
        }
        if (StringUtil.isEmpty(txId)) {
            throw new Error('txId é obrigatório e não foi informado');
        }
        return this.baseService.httpClient.post(this.url(`cancel?bankAccountId=${bankAccountId}&txId=${txId}`, version), null).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    consult(bankAccountId, txId, version) {
        if (!UUIDUtil.isValid(bankAccountId)) {
            throw new Error('Id da conta bancária é obrigatório e não foi informado');
        }
        if (StringUtil.isEmpty(txId)) {
            throw new Error('txId é obrigatório e não foi informado');
        }
        return this.baseService.httpClient.get(this.url(`consult?bankAccountId=${bankAccountId}&txId=${txId}`, version)).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    generate(bankAccountId, value, version) {
        if (!UUIDUtil.isValid(bankAccountId)) {
            throw new Error('Id da conta bancária é obrigatório e não foi informado');
        }
        if (!value) {
            throw new Error('Valor é obrigatório e não foi informado');
        }
        return this.baseService.httpClient.post(this.url('generate', version), { bankAccountId: bankAccountId, value: value }).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialPixService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialPixService_Factory() { return new FinancialPixService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialPixService, providedIn: "root" });
