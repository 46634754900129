import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractService, GIPIBaseService, GIPIPageModel } from '@gipisistemas/ng-core';
import { FinancialIntegrationBillingCancelFilterDTO } from '../models/dto/integration-billing-cancel-filter.dto';
import { FinancialIntegrationBillingConsultDTO } from '../models/dto/integration-billing-consult.dto';
import { FinancialIntegrationBillingFilterDTO } from '../models/dto/integration-billing-filter.dto';
import { FinancialIntegrationBillingInvoiceDTO } from '../models/dto/integration-billing-invoice.dto';

@Injectable({ providedIn: 'root' })
export class FinancialIntegrationBillingService extends GIPIAbstractService {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.integrationBilling, 'v1');
    }

    findAll(filter: FinancialIntegrationBillingFilterDTO, version?: string): Observable<GIPIPageModel<FinancialIntegrationBillingConsultDTO>> {
        return this.baseService.httpClient.post(this.url('', version), filter, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    cancel(filter: FinancialIntegrationBillingCancelFilterDTO, version?: string): Observable<boolean> {
        return this.baseService.httpClient.post(this.url('cancel', version), filter, this.options()).pipe(
            map(_ => true),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    invoice(entity: FinancialIntegrationBillingInvoiceDTO, version?: string): Observable<FinancialBillInstallment> {
        return this.baseService.httpClient.post(this.url('invoice', version), entity, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
