import { catchError, map, takeUntil } from 'rxjs/operators';
import { NotificationPaths } from '@gipi-paths/notification.paths';
import { ArrayUtil, GIPIAbstractService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class NotificationNotifyWhatsappService extends GIPIAbstractService {
    constructor(baseService) {
        super(NotificationPaths.notification, 'v1');
        this.baseService = baseService;
    }
    notificationWhatsapp(filter, version) {
        return this.baseService.httpClient.post(this.url('notification-whatsapp', version), filter, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    notificationWhatsappTotalization(filter, version) {
        return this.baseService.httpClient.post(this.url('notification-whatsapp-totalization', version), filter, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    notifyWhatsapp(notificationList, version) {
        if (ArrayUtil.isEmpty(notificationList)) {
            throw new Error('Informe ao menos uma mensagem à ser enviada');
        }
        return this.baseService.httpClient.post(this.url(`notify-whatsapp`, version), notificationList, this.options()).pipe(map(_ => true), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
NotificationNotifyWhatsappService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationNotifyWhatsappService_Factory() { return new NotificationNotifyWhatsappService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: NotificationNotifyWhatsappService, providedIn: "root" });
