import { FinancialBankAccountType } from '@gipi-financial/bank-account/enums/bank-account-type.enum';
import { GIPIAbstractDTO, GIPIUuid, ObjectUtil, UUIDUtil } from '@gipisistemas/ng-core';
import { FinancialBankAccount } from '../bank-account.model';

export class FinancialBankAccountSelectDTO extends GIPIAbstractDTO {

    /** Código do banco */
    bankCode: string;

    /** Valor atual */
    currentBalance: number;

    /** Descrição */
    description: string;

    /** Indica se tem pix configurado */
    pixConfigured: boolean;

    /** Tipo da conta bancária = ("CHECKING_ACCOUNT" | "CREDIT_CARD" | "SAVINGS" | "INVESTMENT" | "AUTOMATIC_APPLICATION" | "OTHER") */
    type: FinancialBankAccountType;

    constructor(id?: GIPIUuid) {
        super();
        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
    }

    public static transformToDTO(entity: FinancialBankAccount | FinancialBankAccountSelectDTO): FinancialBankAccountSelectDTO {
        if (ObjectUtil.isNull(entity)) {
            return null;
        }
        const isDTO: boolean = ('bankCode' in entity);
        if (!isDTO) {
            const entityAux: FinancialBankAccount = (entity as FinancialBankAccount);
            const bankAccountDTO: FinancialBankAccountSelectDTO = new FinancialBankAccountSelectDTO();
            bankAccountDTO.id = entityAux.id;
            bankAccountDTO.enabled = entityAux.enabled;
            bankAccountDTO.bankCode = !ObjectUtil.isNull(entityAux.bank) ? entityAux.bank.code : '';
            bankAccountDTO.description = entityAux.description;
            bankAccountDTO.pixConfigured = entityAux.pixConfigured;
            bankAccountDTO.type = entityAux.type;
            return bankAccountDTO;
        }

        return ((entity as unknown) as FinancialBankAccountSelectDTO);
    }

}
