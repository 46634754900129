import { NotificationNotifyWhatsappStatus } from '@gipi-notification/notify-whatsapp/enums/notify-whatsapp-status.enum';
import { GIPIAbstractDTO } from '@gipisistemas/ng-core';

export class NotificationNotifyWhatsappTotalizationFilterDTO extends GIPIAbstractDTO {

    endDate: Date;

    startDate: Date;

    status: NotificationNotifyWhatsappStatus;

}
