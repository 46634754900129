import { HttpClient } from '@angular/common/http';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialCostCenterService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.costCenter, http, authenticationService);
    }
    validate(entity) {
        super.validate(entity);
        if (!NumberUtil.isPositive(entity.code) || StringUtil.isEmpty(entity.description)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    getNextCode(upperCost) {
        return this.http.get(this.url(`next-code?upperCategoryId=${ObjectUtil.isNewModel(upperCost) ? 0 : upperCost.id}`), this.options()).pipe(map(this.mapper), takeUntil(this.onDestroy), catchError(this.handleError));
    }
    findByValue(value, page, size, sort, onlyChildren) {
        const validatedValue = value.trim();
        const validatedPage = Math.max(page, 0);
        const validatedSize = Math.max(size, 10);
        const validatedOnlyChildren = ((onlyChildren !== null) && (onlyChildren !== undefined)) ? onlyChildren : true;
        const params = new URLSearchParams();
        params.set('value', validatedValue.toString());
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());
        params.set('onlyChildren', validatedOnlyChildren.toString());
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.property)) {
            params.set('sort', `${sort.property},${sort.direction}`);
        }
        const queryString = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';
        return this.http.get(this.url(`find-by-value${queryString}`), this.options()).pipe(map(this.mapper), takeUntil(this.onDestroy), catchError(this.handleError));
    }
}
FinancialCostCenterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialCostCenterService_Factory() { return new FinancialCostCenterService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialCostCenterService, providedIn: "root" });
