<gipi-abstract-form id="dialogUserInformation"
                    name="dialogUserInformation"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogUserInformation">
        Minhas Informações
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="row"
         fxLayoutGap="25px">
        <div class="avatar"
             fxFlex="25"
             fxLayout="column"
             fxLayoutAlign="start center"
             ngClass.lt-md="avatar-responsive"
             ngClass.lt-lg="avatar-responsive">
            <div fxLayoutAlign="start center"
                 class="avatar-box"
                 [ngClass]="isLoadFile ? 'loader' : '' ">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <img *ngIf="userPhotoSrc && !isLoadFile"
                     [src]="userPhotoSrc">
                <img *ngIf="!userPhotoSrc && !isLoadFile"
                     src="assets/images/placeholders/default-avatar.png"
                     alt="Avatar do usuário"
                     class="photo"
                     style="width: 100%;">
                <itss-button class="button-photo"
                             type="icon"
                             icon="photo_camera"
                             color="primary"
                             (click)="loadFile()"
                             [disabled]="loading || isLoadFile"
                             *ngIf="!userPhotoSrc">
                </itss-button>
                <itss-button class="button-photo"
                             type="icon"
                             svgIcon="trash"
                             color="primary"
                             (click)="deleteFile()"
                             [disabled]="loading || isLoadFile"
                             *ngIf="userPhotoSrc">
                </itss-button>
            </div>
            <input #fileInput
                   [accept]="extensions.join(',')"
                   type="file"
                   style="display:none;">
        </div>
        <div fxLayout="column"
             fxLayoutAlign="end"
             class="user-information-container"
             fxFlex="75"
             fxLayoutGap="16px"
             ngClass.lt-md="user-information-container-responsive"
             ngClass.lt-lg="user-information-container-responsive">
            <div fxLayout="column"
                 fxLayoutGap="16px"
                 fxFlex>
                <itss-input label="Nome"
                            [(ngModel)]="user.name"
                            maxlength="100"
                            required="true"
                            [disabled]="loading"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
                <itss-input label="E-mail"
                            [(ngModel)]="user.username"
                            [disabled]="true"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
            </div>
            <div fxLayout="row"
                 fxLayoutAlign="end"
                 fxLayoutGap="16px"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center"
                 fxFlex>
                <a href="javascript:void(0)"
                   (click)="!loading && changeEditPassword()">
                    {{ editPassword ? 'Desfazer alteração de senha' : 'Alterar senha' }}
                </a>
                <br />
            </div>
            <div *ngIf="editPassword"
                 fxLayout="column"
                 fxLayoutGap="16px"
                 fxFlex>
                <div fxLayout="column"
                     fxLayoutGap="5px">
                    <itss-input label="Nova senha"
                                [type]="isViewNewPassword ? 'text' : 'password'"
                                [required]="editPassword"
                                [(ngModel)]="newPassword"
                                [disabled]="loading"
                                [min]="6"
                                [max]="15"
                                [maxlength]="15"
                                fxFlex="100"
                                (focus)="passwordFocused = true"
                                (blur)="passwordFocused = false">
                        <ng-template #suffix>
                            <gipi-button [tooltip]="isViewNewPassword ? 'Ocultar senha' : 'Exibir senha'"
                                         gipi-icon
                                         id="btnPassword"
                                         [svgIcon]="isViewNewPassword ? 'eye' : 'eye_off'"
                                         [forInput]="true"
                                         (click)="isViewNewPassword = !isViewNewPassword">
                            </gipi-button>
                        </ng-template>
                    </itss-input>

                    <gipi-password-requeriments *ngIf="passwordFocused || (newPassword && (newPassword.length > 0))"
                                                [(ngModel)]="newPassword">
                    </gipi-password-requeriments>
                </div>

                <itss-input label="Confirmar nova senha"
                            [type]="isViewNewPasswordConfirm ? 'text' : 'password'"
                            [disabled]="loading"
                            [required]="editPassword"
                            [(ngModel)]="newPasswordConfirm"
                            [min]="6"
                            [max]="15"
                            [maxlength]="15"
                            fxFlex="100">
                    <ng-template #suffix>
                        <gipi-button [tooltip]="isViewNewPasswordConfirm ? 'Ocultar senha' : 'Exibir senha'"
                                     gipi-icon
                                     id="btnConfirmPassword"
                                     [svgIcon]="isViewNewPasswordConfirm ? 'eye' : 'eye_off'"
                                     [forInput]="true"
                                     (click)="isViewNewPasswordConfirm = !isViewNewPasswordConfirm">
                        </gipi-button>
                    </ng-template>
                </itss-input>
            </div>
            <div fxLayout="row"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center"
                 fxFlex>
                <p>Para alterar suas informações confirme a senha atual</p>
            </div>
            <div fxLayout="row"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign.lt-sm="center"
                 fxFlex>
                <itss-input label="Senha"
                            [type]="isViewValidPassword ? 'text' : 'password'"
                            [inputTrim]="true"
                            [required]="true"
                            [disabled]="loading"
                            [(ngModel)]="validPassword"
                            [min]="6"
                            [max]="15"
                            [maxlength]="15"
                            fxFlex="100">
                    <ng-template #suffix>
                        <gipi-button [tooltip]="isViewValidPassword ? 'Ocultar senha' : 'Exibir senha'"
                                     gipi-icon
                                     [svgIcon]="isViewValidPassword ? 'eye' : 'eye_off'"
                                     [forInput]="true"
                                     (click)="isViewValidPassword = !isViewValidPassword">
                        </gipi-button>
                    </ng-template>
                </itss-input>
            </div>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     [loading]="loading"
                     (click)="save()"
                     [disabled]="loading"
                     fxFlex="25"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close()"
                     [disabled]="loading"
                     fxFlex="25"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>