import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment_ from 'moment';
const moment = moment_;
import { FinancialBillAssessment } from '@gipi-financial/bill/models/bill-assessment.model';
import { FinancialCostCenterService } from '@gipi-financial/cost-center/services/cost-center.service';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { FinancialReceivementCardAdministrator } from '@gipi-financial/receivement-card-administrator/models/receivement-card-administrator.model';
import { FinancialReceivementCardService } from '@gipi-financial/receivement-card-administrator/services/receivement-card.service';
import { APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, INJECTOR, NumberUtil, ObjectUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class ReceivementCardAdministratorFormDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, _postingCategoryService, _costCenterService, data = { ids: [] }, dialogRef) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._postingCategoryService = _postingCategoryService;
        this._costCenterService = _costCenterService;
        this.data = data;
        this.dialogRef = dialogRef;
        this._receivementCardList = [];
        this._receivementCardAdministratorList = [];
        this.costCenterColumns = this._createTableColumnsCostCenter();
        this.receivementDate = moment().startOf('day').toDate();
        this.receivementPostingCategory = null;
        this.receivementAmounts = {
            originalValue: 0,
            discountRate: 0,
            discountAnticipation: 0,
            amountNet: 0,
        };
        this.isAnticipation = false;
        this.costCenterExludList = [];
        this.assessment = new FinancialBillAssessment();
        this.assessmentList = [];
        this.postingCategoryFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._postingCategoryService.findByValue(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'DEBIT', true).toPromise();
            return result;
        });
        this.postingCategoryFn = (obj) => `${obj.fullCode} - ${obj.description}`;
        this.costCenterFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._costCenterService.findByValue(value, page, 10, { property: 'fullCode', direction: 'asc' }, true).toPromise();
            this._removeCostCenter(result.content);
            return result;
        });
        this.costCenterFn = (obj) => `${obj.fullCode} - ${obj.description}`;
    }
    get totalPercentageAssessment() {
        return this.assessmentList.reduce((sum, e) => sum += e.percentage, 0);
    }
    get amountNetDiscount() {
        return this._receivementCardAdministratorList.reduce((sum, e) => sum += (e.discountRate + e.discountAnticipation), 0);
    }
    ngOnInit() {
        super.ngOnInit();
        this._findReceivementsCard();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    _createTableColumnsCostCenter() {
        return [
            TableColumnBuilder.instance()
                .property('code')
                .name('Código')
                .align('center center')
                .width(10)
                .align('center center')
                .value((obj) => obj.costCenter.fullCode)
                .build(),
            TableColumnBuilder.instance()
                .property('description')
                .name('Centro de custo')
                .value((obj) => obj.costCenter.description)
                .build(),
            TableColumnBuilder.instance()
                .property('percentage')
                .name('Percentual')
                .value((obj) => obj.percentage + '%')
                .width(10)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .value((obj) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .width(10)
                .align('center center')
                .build()
        ];
    }
    _findReceivementsCard() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (ObjectUtil.isNull(this.data) ||
                    (!ObjectUtil.isNull(this.data) && ArrayUtil.isEmpty(this.data.ids))) {
                    return;
                }
                this.isAnticipation = this.data.anticipation;
                const receivementCardList = yield this.service.findByIds(this.data.ids).toPromise().catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                    return [];
                });
                if (!ArrayUtil.isEmpty(receivementCardList)) {
                    this._receivementCardList = ArrayUtil.clone(receivementCardList);
                    // Gera a lista de recebimentos da administradora de cartão com base no recebimento de cartão
                    this._generateReceivementCardAdministratorList();
                }
            }
            catch (error) {
                this.loading = false;
                this.handleError(error);
            }
        });
    }
    _getNumberMonths(initial, final) {
        const startDate = moment(initial).startOf('day'); // Inicia o dia em 00:00
        const endDate = moment(final).startOf('day'); // Inicia o dia em 00:00
        // Calcula a diferença em meses entre as duas datas
        const months = endDate.diff(startDate, 'months', true);
        // Arredonda o número de meses para o inteiro superior
        return Math.ceil(months);
    }
    _generateReceivementCardAdministratorList() {
        this._receivementCardAdministratorList = [];
        let discountRate = 0;
        let originalValue = 0;
        let discountAnticipation = 0;
        for (const receivementCard of this._receivementCardList) {
            const receivementCardAdministrator = new FinancialReceivementCardAdministrator();
            receivementCardAdministrator.id = null;
            receivementCardAdministrator.enabled = true;
            receivementCardAdministrator.dateReceivement = new Date();
            receivementCardAdministrator.companyId = receivementCard.companyId;
            receivementCardAdministrator.receivementCard = ObjectUtil.clone(receivementCard);
            // Valor original
            receivementCardAdministrator.amountNet = receivementCard.amountNet;
            originalValue += receivementCardAdministrator.amountNet;
            // Valor da taxa
            receivementCardAdministrator.discountRate = (receivementCard.amountCard - receivementCard.amountNet);
            discountRate += receivementCardAdministrator.discountRate;
            // Valor da taxa antecipação
            receivementCardAdministrator.discountAnticipation = 0;
            if (this.data.anticipation) {
                const anticipationFee = ((receivementCard.chargeType.type === 'CREDIT_CARD')
                    ? receivementCard.cardAdministrator.creditAnticipationFee
                    : receivementCard.cardAdministrator.debitAnticipationFee);
                const dueDate = new Date(moment(receivementCard.dueDate).format('yyyy/MM/DD'));
                if (DateUtil.isLessThan(this.receivementDate, dueDate)) {
                    const months = this._getNumberMonths(this.receivementDate, dueDate);
                    receivementCardAdministrator.discountAnticipation = (receivementCardAdministrator.amountNet * ((anticipationFee * months) / 100));
                    discountAnticipation += receivementCardAdministrator.discountAnticipation;
                }
                receivementCardAdministrator.amountAnticipation = (receivementCardAdministrator.amountNet - receivementCardAdministrator.discountAnticipation);
            }
            else {
                discountAnticipation += receivementCardAdministrator.discountAnticipation;
            }
            this._receivementCardAdministratorList.push(receivementCardAdministrator);
        }
        this.receivementAmounts = {
            originalValue: originalValue,
            discountRate: discountRate,
            discountAnticipation: discountAnticipation,
            amountNet: (originalValue - (discountRate + discountAnticipation)),
        };
    }
    _removeCostCenter(costCenterList) {
        for (let i = 0; i < this.assessmentList.length; i++) {
            const billAssessment = this.assessmentList[i];
            const costCenter = costCenterList.find(costCenter => costCenter.id === billAssessment.costCenter.id);
            if (!ObjectUtil.isNull(costCenter)) {
                this.costCenterExludList.push(costCenter);
            }
            costCenterList.sort(ArrayUtil.sortObjects([{ property: 'fullCode', isAsc: true }]));
        }
    }
    _loadCostCenterAssessment() {
        this.assessmentList = [];
        if (!ObjectUtil.isNull(this.receivementPostingCategory.costCenterAssessment)) {
            for (const costCenterAssessment of this.receivementPostingCategory.costCenterAssessment.costCenterAssessmentCostCenterList) {
                const assessment = new FinancialBillAssessment();
                assessment.costCenter = costCenterAssessment.costCenter;
                assessment.percentage = costCenterAssessment.percentage;
                const amountNet = this.amountNetDiscount;
                assessment.amount = (amountNet * (assessment.percentage / 100));
                this.addAssessment(assessment);
            }
        }
    }
    _validateAssessment() {
        if (ObjectUtil.isNull(this.assessment)) {
            return false;
        }
        if (ObjectUtil.isNull(this.assessment.costCenter)) {
            this.addWarningMessage('Campo centro de custo é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.percentage)) {
            this.addWarningMessage('Campo percentual é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.percentage)) {
            this.addWarningMessage('O percentual informado não pode ser superior à 100');
            return false;
        }
        if ((this.totalPercentageAssessment + this.assessment.percentage) > 100) {
            this.addWarningMessage('A soma dos percentuais não pode ser superior à 100');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.amount)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }
        return true;
    }
    calculateAssessmentAmount() {
        const amountNet = this.amountNetDiscount;
        this.assessment.amount = (amountNet * (this.assessment.percentage / 100));
        this.assessmentList.forEach(assessment => assessment.amount = (amountNet * (assessment.percentage / 100)));
    }
    addAssessment(entity) {
        try {
            this.assessment = (!ObjectUtil.isNull(entity) ? entity : this.assessment);
            if (!this._validateAssessment()) {
                return;
            }
            const assessmentList = [];
            assessmentList.push(...this.assessmentList, ObjectUtil.clone(this.assessment));
            this.assessmentList = assessmentList;
            this.costCenterExludList.push(this.assessment.costCenter);
            this.assessment = new FinancialBillAssessment();
            this.assessment.percentage = (100 - this.totalPercentageAssessment);
            this.calculateAssessmentAmount();
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    removeAssessment(entity) {
        try {
            const assessmentList = [];
            const indexCostCenter = this.costCenterExludList.findIndex(cc => cc.id === entity.costCenter.id);
            if (indexCostCenter >= 0) {
                this.costCenterExludList.splice(indexCostCenter, 1);
            }
            assessmentList.push(...this.assessmentList);
            assessmentList.splice(assessmentList.indexOf(entity), 1);
            this.assessmentList = assessmentList;
            this.assessment.percentage = (100 - this.totalPercentageAssessment);
            this.calculateAssessmentAmount();
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    recalculateFees() {
        if (!ObjectUtil.isNull(this.receivementDate) &&
            DateUtil.isValid(new Date(this.receivementDate))) {
            this._generateReceivementCardAdministratorList();
        }
    }
    postingCategorySelectionChange(entity) {
        if (ObjectUtil.isNull(entity)) {
            return;
        }
        if (!ObjectUtil.isNull(this.receivementPostingCategory.costCenterAssessment)) {
            this._loadCostCenterAssessment();
        }
        else {
            this.assessmentList = [];
            this.assessment.percentage = (100 - this.totalPercentageAssessment);
        }
    }
    confirm() {
        try {
            if (ObjectUtil.isNull(this.receivementDate) || !DateUtil.isValid(this.receivementDate)) {
                this.addWarningMessage('Campo data do recebimento é obrigatório e não foi informado');
                this.loading = false;
                return;
            }
            if (ObjectUtil.isNull(this.receivementPostingCategory)) {
                this.addWarningMessage('Campo categoria é obrigatório e não foi informado');
                this.loading = false;
                return;
            }
            this.loading = true;
            for (const receivementCardAdministrator of this._receivementCardAdministratorList) {
                receivementCardAdministrator.postCategory = new FinancialPostingCategory(this.receivementPostingCategory.id);
                receivementCardAdministrator.dateReceivement = moment(this.receivementDate).toDate();
                for (const assessment of this.assessmentList) {
                    const billAssessment = ObjectUtil.clone(assessment);
                    billAssessment.amount = Number.parseFloat((receivementCardAdministrator.amountNet * billAssessment.percentage / 100).toFixed(2));
                    receivementCardAdministrator.assessmentList.push(billAssessment);
                }
            }
            for (const assessment of this.assessmentList) {
                let amountTotal = 0;
                this._receivementCardAdministratorList.forEach((receivementCardAdministrator) => {
                    receivementCardAdministrator.assessmentList.forEach((assess) => {
                        if (assess.costCenter.fullCode === assessment.costCenter.fullCode) {
                            amountTotal = amountTotal + assess.amount;
                        }
                    });
                });
                if (amountTotal > assessment.amount) {
                    this._receivementCardAdministratorList[this._receivementCardAdministratorList.length - 1].assessmentList.forEach((assess) => {
                        if (assess.costCenter.fullCode === assessment.costCenter.fullCode) {
                            assess.amount = assess.amount - (amountTotal - assessment.amount);
                        }
                    });
                }
                else if (amountTotal < assessment.amount) {
                    this._receivementCardAdministratorList[this._receivementCardAdministratorList.length - 1].assessmentList.forEach((assess) => {
                        if (assess.costCenter.fullCode === assessment.costCenter.fullCode) {
                            assess.amount = assess.amount + (assessment.amount - amountTotal);
                        }
                    });
                }
            }
            this.service.receive(this._receivementCardAdministratorList).toPromise().then(_ => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close('RELOAD_TABLE');
            }).catch(error => {
                this.loading = false;
                this.handleError(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
