import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBillAssessment } from '@gipi-financial/bill/models/bill-assessment.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialReceivementCard } from './receivement-card.model';

export class FinancialReceivementCardAdministrator extends GIPIBaseAbstractModel {

    /** Valor da antecipação do recebimento */
    amountAnticipation: number;

    /** @Required Valor líquido do recebimento */
    amountNet: number;

    /** Lista de rateio do centro de custo */
    assessmentList: FinancialBillAssessment[];

    /** @Required Conta bancária */
    bankAccount: FinancialBankAccount;

    /** Indica se foi criado pela conciliação */
    createdByConciliation: boolean;

    /** @Required Data do recebimento */
    dateReceivement: Date;

    /** Valor de desconto da antecipação do recebimento */
    discountAnticipation: number;

    /** @Required Taxa de desconto do recebimento */
    discountRate: number;

    finacialReceivementCardAdministratorId: GIPIUuid;

    /** @Required Plano de contas */
    postCategory: FinancialPostingCategory;

    /** @Required Recebimento de cartão */
    receivementCard: FinancialReceivementCard;

    constructor() {
        super();
        this.assessmentList = [];
    }

}
