import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialBilletFinancialService } from '@gipi-billet/bank-slip/services/billet-financial.service';
import { FinancialBankSlipConfiguration } from '@gipi-financial/bank-account/models/bank-slip-configuration.model';
import { FinancialBankAccountSelectDTO } from '@gipi-financial/bank-account/models/dto/bank-account-select.dto';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialBankSlipConfigurationService } from '@gipi-financial/bank-account/services/bank-slip-configuration.service';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBill } from '@gipi-financial/bill/models/bill.model';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialChargeTypeService } from '@gipi-financial/charge-type/services/charge-type.service';
import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialIntegrationBillingConsultDTO } from '@gipi-financial/integration-billing/models/dto/integration-billing-consult.dto';
import { FinancialIntegrationBillingInvoiceDTO } from '@gipi-financial/integration-billing/models/dto/integration-billing-invoice.dto';
import { FinancialIntegrationBillingService } from '@gipi-financial/integration-billing/services/integration-billing.service';
import { FinancialPostingCategorySelectDTO } from '@gipi-financial/posting-category/models/dto/posting-category-select.dto';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { BankCodes, BankCodeUtil } from '@gipi-shared/utils/bank-codes-billet.util';
import { APP_MESSAGES, ArrayUtil, AuthenticationService, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, GIPISortModel, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export interface IntegrationBillingData {
    integrationBilling: FinancialIntegrationBillingConsultDTO;
    startDate: Date;
    endDate: Date;
}

@Component({
    selector: 'gipi-integration-billing-form-dialog',
    templateUrl: './integration-billing-form-dialog.component.html',
    styleUrls: ['integration-billing-form-dialog.component.scss'],
})
export class IntegrationBillingFormDialogComponent extends GIPIAbstractComponent implements OnInit {

    private _userLoggedToken: OAuthUser;

    public billInstallment: FinancialBillInstallment = this._newEntity();

    public chargeTypeList: FinancialChargeType[] = [];
    public clientList: FinancialClient[] = [];

    bankAccountFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialBankAccountSelectDTO> = await this._bankAccountService.findByValue(value, page, 10, { property: 'description', direction: 'asc' }).toPromise();
        return result;
    };

    postingCategoryFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialPostingCategorySelectDTO> = await this._postingCategoryService.findByValue(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'CREDIT', true).toPromise();
        return result;
    };
    postingCategoryFn = (obj: FinancialPostingCategorySelectDTO) => `${obj.fullCode} - ${obj.description}`;

    clientFn = (obj: FinancialClient) => this._clientService.getDescription(obj);

    constructor(
        protected service: FinancialIntegrationBillingService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _authenticationService: AuthenticationService<OAuthUser>,
        private _billInstallmentService: FinancialBillInstallmentService,
        private _postingCategoryService: FinancialPostingCategoryService,
        private _clientService: FinancialClientService,
        private _chargeTypeService: FinancialChargeTypeService,
        private _bankAccountService: FinancialBankAccountService,
        private _bankSlipConfigurationService: FinancialBankSlipConfigurationService,
        private _billetFinancialService: FinancialBilletFinancialService,
        @Inject(MAT_DIALOG_DATA) public data: IntegrationBillingData,
        public dialogRef: MatDialogRef<IntegrationBillingFormDialogComponent>,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        super.ngOnInit();

    }

    private async _findChargeType(billInstallment: FinancialBillInstallment): Promise<void> {
        this.chargeTypeList = await this._chargeTypeService.findByTypes(['BANK_SLIP', 'STORE_CREDIT',]).toPromise();

        if (!ObjectUtil.isNull(billInstallment) && !ArrayUtil.isEmpty(this.chargeTypeList)) {
            billInstallment.chargeType = this.chargeTypeList.find(e => e.type === 'BANK_SLIP');
        }
    }

    private async _findClient(billInstallment: FinancialBillInstallment): Promise<void> {
        if (
            !ObjectUtil.isNull(this.data) &&
            !ObjectUtil.isNull(this.data.integrationBilling) &&
            !StringUtil.isEmpty(this.data.integrationBilling.cnpj)
        ) {
            this.clientList = await this._clientService.findByCpfOrCnpj(this.data.integrationBilling.cnpj).toPromise();
        }

        if (!ObjectUtil.isNull(billInstallment) && !ArrayUtil.isEmpty(this.clientList)) {
            billInstallment.bill.client = ObjectUtil.clone(this.clientList[0]);
        }
    }

    private _newEntity(): FinancialBillInstallment {
        const billInstallment: FinancialBillInstallment = new FinancialBillInstallment();

        this._userLoggedToken = this._authenticationService.token.user;

        this._findChargeType(billInstallment);
        this._findClient(billInstallment);

        billInstallment.bill = new FinancialBill();
        billInstallment.bill.type = 'RECEIVABLE';
        billInstallment.bill.allowModification = false;
        billInstallment.userId = this._userLoggedToken.id;
        billInstallment.status = 'RECEIVABLE';
        billInstallment.conciled = false;
        billInstallment.createdByConciliation = false;
        billInstallment.issuanceDate = new Date();
        billInstallment.dueDate = new Date();
        billInstallment.paymentDate = null;
        billInstallment.dischargeDate = null;
        billInstallment.dischargeAmount = 0;

        billInstallment.amount = 0;
        if (!ObjectUtil.isNull(this.data) && !ObjectUtil.isNull(this.data.integrationBilling)) {
            billInstallment.amount = this.data.integrationBilling.totalPrice;

            const startDate: string = DateUtil.format(this._getDateFormated(this.data.startDate), DateUtil.DATE_FORMAT);
            const endDate: string = DateUtil.format(this._getDateFormated(this.data.endDate), DateUtil.DATE_FORMAT);
            billInstallment.description = `COBRANÇA REF. A INTEGRAÇÃO COM WHATSAPP DE ${startDate} A ${endDate}`;
        }

        return billInstallment;
    }

    private _getDateFormated(input: Date): Date {
        if (ObjectUtil.isNull(input)) {
            return null;
        }
        const dateAux: Date = new Date(input);
        dateAux.setHours(0);
        dateAux.setMinutes(0)
        dateAux.setSeconds(0);
        dateAux.setMilliseconds(0);
        return dateAux;
    }

    public async invoice(): Promise<void> {
        try {
            this.loading = true;

            const messageValidate: string = this._billInstallmentService.isValid(this.billInstallment);
            if (!StringUtil.isEmpty(messageValidate)) {
                this.addWarningMessage(messageValidate);
                this.loading = false;
                return;
            }

            if (this.billInstallment.chargeType.type === 'BANK_SLIP') {
                if (this.billInstallment.bankAccount.type !== 'CHECKING_ACCOUNT') {
                    this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja do tipo "Conta corrente"');
                    this.loading = false;
                    return;
                }

                const bankAccount: FinancialBankAccountSelectDTO = ((this.billInstallment.bankAccount as unknown) as FinancialBankAccountSelectDTO);
                const allowedBankAccount: boolean = BankCodeUtil.isValidBankCode((bankAccount.bankCode as BankCodes));
                if (!allowedBankAccount) {
                    this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja válida');
                    this.loading = false;
                    return;
                }

                const bankSlipConfiguration: FinancialBankSlipConfiguration = await this._bankSlipConfigurationService.findByBankAccountId(this.billInstallment.bankAccount.id).toPromise();
                if (ObjectUtil.isNull(bankSlipConfiguration)) {
                    this.addWarningMessage('Boleto não configurado para a conta bancária selecionada');
                    this.loading = false;
                    return;
                }
            }

            this.billInstallment.originalAmount = this.billInstallment.amount;
            this.billInstallment.ourNumber = null;
            this.billInstallment.createdByIntegrationInvoice = true;

            const invoice: FinancialIntegrationBillingInvoiceDTO = new FinancialIntegrationBillingInvoiceDTO();
            invoice.billInstallment = ObjectUtil.clone(this.billInstallment);
            invoice.cnpj = this.data.integrationBilling.cnpj;
            invoice.startDate = this.data.startDate;
            invoice.endDate = this.data.endDate;

            const billInstallmentReturn: FinancialBillInstallment = await this.service.invoice(invoice).toPromise().catch(error => {
                this.addErrorMessage(error);
                this.loading = false;
                return null;
            });

            if (!ObjectUtil.isNull(billInstallmentReturn)) {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.billInstallment = billInstallmentReturn;

                if (this.billInstallment.chargeType.type === 'BANK_SLIP') {
                    this._confirmGenerateBankSlip();
                }
            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private async _confirmGenerateBankSlip(): Promise<void> {
        try {
            this.loading = true;
            const isConfirmed: boolean = await this.baseService.confirmationService.confirm({
                title: 'Confirmação',
                message: 'Deseja imprimir o boleto?',
            });

            if (isConfirmed) {
                this._billetFinancialService.generateBillet(this.billInstallment, true).toPromise().then(resp => {
                    const file: Blob = new Blob([resp.body], { type: 'application/pdf' });
                    const fileURL: string = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.close('RELOAD_TABLE');
                }, error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                });
            } else {
                this._billetFinancialService.generateBillet(this.billInstallment, false).subscribe();
                this.close('RELOAD_TABLE');
            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
