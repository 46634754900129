import { GIPIAbstractModel, GIPIUuid } from '@gipisistemas/ng-core';

export class GIPIBaseAbstractModel extends GIPIAbstractModel {

    id: GIPIUuid;

    companyId: GIPIUuid;

    integrationIdentifier: GIPIUuid;

    tenantId: GIPIUuid;

    enabled: boolean = true;

    createdDate: Date;

    modifiedDate: Date;

    migrated: boolean;

}
