import { FinancialCostCenter } from '@gipi-financial/cost-center/models/cost-center.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class FinancialBillAssessment extends GIPIBaseAbstractModel {

    /** @Required Valor */
    amount: number;

    /** @Required Centro de custo */
    costCenter: FinancialCostCenter;

    /** @Required Percentual */
    percentage: number;

    saleBillAssessmentId: number;

    constructor() {
        super();
        this.percentage = 100;
    }

}
