<gipi-abstract-form id="dialogIntegrations"
                    name="dialogIntegrations"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogIntegrations">
        Integração da Connect S/A
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">
        <div id="panelConnectInfo"
             fxLayout="column"
             fxLayoutGap="32px">
            <div id="panelConnectInfoCredentials"
                 class="content-information">
                <label for="contentInformation">
                    Informações das credenciais
                </label>
                <div id="contentInformation"
                     fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px">
                    <p>
                        Para realizar a integração, é necessário obter as credenciais. Por favor, entre em contato com a
                        <a id="panelConnectContactConnectSA"
                           href="https://www.connectsa.com.br/seja-cliente-gipi-sistemas/"
                           target="_blank"
                           class="link-connect">
                            Connect SA
                        </a>
                        para solicitá-las. Após receber os dados necessários, você poderá inseri-los abaixo e concluir o
                        processo.
                    </p>
                </div>
            </div>

            <div id="panelConnectInputsCredentials"
                 fxLayout="column"
                 fxLayoutGap="16px">
                <div fxLayout="row"
                     fxLayoutGap="16px"
                     fxFlex>
                    <gipi-input-select-enum label="Situação"
                                            id="panelConnectEnabled"
                                            name="panelConnectEnabled"
                                            [required]="true"
                                            [disabled]="loading && (panelConnectIsNewRecord | async)"
                                            [valuesExclud]="[]"
                                            [enum]="panelConnectOptionActiveSituationEnum"
                                            [(ngModel)]="panelConnectOptionActiveSituationValue"
                                            fxFlex
                                            fxFlex.lt-md="100">
                    </gipi-input-select-enum>
                    <itss-input label="ID do cliente"
                                id="panelConnectIdClient"
                                name="panelConnectIdClient"
                                [required]="true"
                                [disabled]="loading"
                                [(ngModel)]="connectConfig.idClient"
                                [max]="254"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>
                    <itss-input label="Login"
                                id="panelConnectLogin"
                                name="panelConnectLogin"
                                [required]="true"
                                [disabled]="loading"
                                [(ngModel)]="connectConfig.login"
                                [max]="254"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>

                    <itss-input label="Senha"
                                #panelConnectInputPassword
                                id="panelConnectPassword"
                                name="panelConnectPassword"
                                [type]="panelConnectBtnViewPassword ? 'text' : 'password'"
                                [inputTrim]="true"
                                [required]="true"
                                [disabled]="loading"
                                [(ngModel)]="connectConfig.password"
                                [max]="254"
                                fxFlex
                                fxFlex.lt-md="100">
                        <ng-template #suffix>
                            <gipi-button [tooltip]="panelConnectBtnViewPassword ? 'Ocultar senha' : 'Exibir senha'"
                                         gipi-icon
                                         id="panelConnectBtnViewPassword"
                                         name="panelConnectBtnViewPassword"
                                         [disabled]="loading"
                                         [forInput]="true"
                                         [svgIcon]="panelConnectBtnViewPassword ? 'eye' : 'eye_off'"
                                         (click)="panelConnectBtnViewPassword = !panelConnectBtnViewPassword">
                            </gipi-button>
                        </ng-template>
                    </itss-input>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnIntegrationsConfirm"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnIntegrationsReturn"
                     (click)="close()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
    </div>
</gipi-abstract-form>