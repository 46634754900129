import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import * as moment from 'moment';

import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBillInstallmentConsultDTO } from '@gipi-financial/bill/models/dto/bill-installment-consult.dto';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialCashierShiftService } from '@gipi-financial/cashier-shift/services/cashier-shift.service';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialChargeTypeService } from '@gipi-financial/charge-type/services/charge-type.service';
import { FinancialClientCardDTO } from '@gipi-financial/client/models/dto/client-card.dto';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialConfigurationService } from '@gipi-financial/configuration/services/configuration.service';
import { PixDialogComponent, PixDialogData } from '@gipi-financial/pix/components/pix-dialog/pix-dialog.component';
import { FinancialReceivement } from '@gipi-financial/receivement/models/receivement.model';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { ArrayUtil, AuthenticationService, BrowserUtil, DateUtil, DialogService, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, InputComponent, NumberUtil, ObjectUtil, PageDTO, StringUtil, TokenDTO } from '@gipisistemas/ng-core';
import { ReceivementCardData, ReceivementCardDialogComponent } from '../receivement-card-dialog/receivement-card-dialog.component';
import { ReceivementCheckData, ReceivementCheckDialogComponent } from '../receivement-check-dialog/receivement-check-dialog.component';
import { ReceivementDepositData, ReceivementDepositDialogComponent } from '../receivement-deposit-dialog/receivement-deposit-dialog.component';

export interface ReceivementDialogData {
    billInstallmentList: FinancialBillInstallmentConsultDTO[];
    cashierShift?: FinancialCashierShift;
}

@Component({
    templateUrl: './receivement-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class ReceivementDialogComponent extends GIPIAbstractComponent implements OnInit {

    @ViewChild('firstInput', { static: true }) firstInput: InputComponent;

    dataBillInstallmentList: FinancialBillInstallment[] = [];

    installmentList: FinancialBillInstallment[] = [];

    bankAccountList: FinancialBankAccount[] = [];

    chargeTypeOriginalList: FinancialChargeType[] = [];

    chargeTypeList: FinancialChargeType[] = [];

    amountNet: number = 0;

    clientCreditBalance: number = 0;

    useClientCredit: boolean = false;

    postClientCredit: boolean = false;

    receivement: FinancialReceivement = new FinancialReceivement();

    isCashier: boolean = false;

    configuration: FinancialConfiguration;

    clientCredit: number = 0;

    isLoad: boolean = false;

    amountTotalInstallment: number = 0;

    originalInterestAmount: number = 0;

    originalFineAmount: number = 0;

    generatedInterest: boolean = false;

    generatedFine: boolean = false;

    currentDate: Date = new Date();

    printReceipt: boolean = false;

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: ReceivementDialogData = { billInstallmentList: [], cashierShift: null },
        public dialogRef: MatDialogRef<ReceivementDialogComponent>,
        private _receivementService: FinancialReceivementService,
        private _chargeTypeService: FinancialChargeTypeService,
        private _bankAccountService: FinancialBankAccountService,
        private _clientService: FinancialClientService,
        private _authenticationService: AuthenticationService<OAuthUser>,
        private _cashierShiftService: FinancialCashierShiftService,
        private _configurationService: FinancialConfigurationService,
        private _billInstallmentService: FinancialBillInstallmentService,
        private _dialogService: DialogService,
        private _breakpointObserver: BreakpointObserver
    ) {
        super(baseService, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.isLoad = true;
        this.receivement.interest = 0;
        this.receivement.fine = 0;
        this.receivement.discount = 0;
        this.receivement.receivementDate = new Date();
        this.receivement.usedCustomerCredit = 0;
        this.useClientCredit = false;

        this.receivement.addition = 0;

        const installmentList: FinancialBillInstallment[] = await this._billInstallmentService.findByIdIn(this.data.billInstallmentList.map(bi => bi.id)).toPromise();
        if (!ArrayUtil.isEmpty(installmentList)) {
            this.dataBillInstallmentList = ArrayUtil.clone(installmentList);
            this.installmentList = ArrayUtil.clone(installmentList);
        }

        this.isCashier = this._userIsCashier();

        if (this.isCashier) {
            if (ObjectUtil.isNewModel(this.data.cashierShift)) {
                await this._getCashierShiftOpenedByUser(true).then(shift => {
                    if (shift) {
                        this.receivement.shift = shift;
                    } else {
                        this.close('REMARK_SELECTED', null, false);
                    }
                });
            } else {
                this.receivement.shift = this.data.cashierShift;
            }
        } else {
            this.receivement.shift = null;
            this.receivement.bankAccount = null;
        }

        await this.initializeLists();
        await this.calculateInstallmentAmount();

        this.generatedInterest = !(this.receivement.interest > 0);
        this.generatedFine = !(this.receivement.fine > 0);

        this.originalFineAmount = this.receivement.fine;
        this.originalInterestAmount = this.receivement.interest;

        this.amountTotalInstallment = this.dataBillInstallmentList.reduce((sum, billInstallment) => sum += billInstallment.amount, 0);

        this.printReceipt = this.configuration.printReceiptAfterReceivement;

        this.isLoad = false;
        this.setFocus();
    }

    private setFocus(): void {
        setTimeout(() => this.firstInput._elementRef.nativeElement.focus());
    }

    private async _getCashierShiftOpenedByUser(closeModal: boolean): Promise<FinancialCashierShift> {
        return new Promise(async (resolve, reject) => {
            if (this.isCashier) {
                await this._cashierShiftService.getOpenedByCurrentUser().toPromise().then(cashierShift => {
                    if (ObjectUtil.isNewModel(cashierShift)) {
                        resolve(null);
                        if (closeModal) {
                            this.close('REMARK_SELECTED', null, false);
                        }
                        this.handleError('Não é possivel realizar o recebimento. Operador de caixa deve abrir um turno');
                    } else {
                        resolve(cashierShift);
                    }
                }, (error) => {
                    resolve(null);
                    if (error.toLowerCase().includes('a consulta não retornou resultado único')) {
                        this.handleError(`Erro ao buscar turnos. ${error}`);
                    } else {
                        this.handleError(error);
                    }
                });
            }
        });
    }

    private _userIsCashier(): boolean {
        const token: TokenDTO<OAuthUser> = this._authenticationService.token;
        return (!ObjectUtil.isNull(token) && token.user.cashier);
    }

    async validateCashierOpened(): Promise<boolean> {
        if (this.isCashier) {
            const cashierShift: FinancialCashierShift = await this._getCashierShiftOpenedByUser(false).then(shift => shift);
            return Promise.resolve(!ObjectUtil.isNewModel(cashierShift));
        } else {
            return Promise.resolve(true);
        }
    }

    async calculateInstallmentAmount(): Promise<void> {
        this.installmentList = [];

        let amountTotalInstallment: number = this.dataBillInstallmentList.reduce((sum, billInstallment) => sum += billInstallment.amount, 0);
        let amountClientCredit: number = this.clientCreditBalance;

        this.installmentList = this.dataBillInstallmentList.map(installment => {
            if (!NumberUtil.isPositive(installment.originalAmount)) {
                installment.originalAmount = installment.amount;
            }

            const lDate: Date = new Date();
            const nowDate: Date = new Date(lDate.getFullYear(), lDate.getMonth(), lDate.getDate(), 0, 0, 0, 0);
            let dueDate: Date = new Date(moment(installment.dueDate).format('YYYY-MM-DD[T]HH:mm:ss'));
            let graceDate: Date = new Date(moment(installment.dueDate).format('YYYY-MM-DD[T]HH:mm:ss'));
            const dischargeDate = new Date(moment(installment.dischargeDate).format('YYYY-MM-DD[T]HH:mm:ss'));

            if (!ObjectUtil.isNull(installment.dischargeDate) && !DateUtil.isGreaterThan(dueDate, dischargeDate, 'date')) {
                this.configuration.graceDays = 1;
                dueDate = new Date(moment(installment.dischargeDate).format('YYYY-MM-DD[T]HH:mm:ss'))
            }

            graceDate = new Date(moment(dueDate).add(this.configuration.graceDays, 'day').format('YYYY-MM-DD[T]HH:mm:ss'));

            // graceDate.setDate(dueDate.getDate() + this.configuration.graceDays);

            // Só entra se a data de vencimento + os dias de carência for menor que a data atual.
            if (DateUtil.isLessThan(graceDate, nowDate, 'date')) {

                // Se o cliente não tiver crédito ou o valor da conta for maior que o valor do crédito, vai calcular o juro e a multa.
                if ((amountClientCredit <= 0) || (amountClientCredit < amountTotalInstallment)) {

                    let amountInstallment: number = installment.amount - amountClientCredit;
                    amountInstallment = (amountInstallment > 0 ? amountInstallment : 0);

                    amountTotalInstallment -= installment.amount;

                    amountClientCredit -= installment.amount;
                    amountClientCredit = (amountClientCredit > 0 ? amountClientCredit : 0);

                    if (amountInstallment > 0) {
                        // Calcula os dias vencidos
                        const diffDate: number = Math.abs(nowDate.getTime() - dueDate.getTime());
                        const dueDays: number = Math.ceil(diffDate / 86400000);

                        // Valor do juro
                        const interest: number = ((this.configuration.interest / 30) * dueDays);

                        installment.interest = ((amountInstallment * interest) / 100);
                        this.receivement.interest = Number((this.receivement.interest + installment.interest).toFixed(2));

                        // Valor da multa
                        installment.fine = installment.fineCharged ? 0 : ((amountInstallment * this.configuration.fine) / 100);
                        this.receivement.fine = Number((this.receivement.fine + installment.fine).toFixed(2));
                    }
                }
            }

            return installment;
        });

        this.setAmountNet();

        return Promise.resolve();
    }

    async initializeLists(): Promise<void> {
        const configuration: FinancialConfiguration = await this._configurationService.findEnabled().toPromise().catch(error => {
            this.isLoad = false;
            this.loading = false;
            this.addErrorMessage(error);
            return null;
        });

        if (!ObjectUtil.isNull(configuration)) {
            this.configuration = ObjectUtil.clone(configuration);
        }

        const clientCard: FinancialClientCardDTO = await this._clientService.getCard<FinancialClientCardDTO>(this.dataBillInstallmentList[0].bill.client.id).toPromise().catch(error => {
            this.isLoad = false;
            this.loading = false;
            this.addErrorMessage(error);
            return null;
        });

        if (!ObjectUtil.isNull(clientCard)) {
            this.clientCreditBalance = clientCard.creditBalance;
        }

        if (!this.isCashier) {
            const bankAccountPage: PageDTO<FinancialBankAccount> = await this._bankAccountService.findByValue('', 0, 100, { property: 'description', direction: 'asc' }).toPromise().catch(error => {
                this.isLoad = false;
                this.loading = false;
                this.addErrorMessage(error);
                return null;
            });

            if (!ObjectUtil.isNull(bankAccountPage) && !ArrayUtil.isEmpty(bankAccountPage.content)) {
                this.bankAccountList = ArrayUtil.clone(bankAccountPage.content);
            }

            if (!ArrayUtil.isEmpty(this.bankAccountList) && this.bankAccountList.length === 1) {
                this.receivement.bankAccount = ObjectUtil.clone(this.bankAccountList[0]);
            }
        }

        const chargeTypePage: GIPIPageModel<FinancialChargeType> = await this._chargeTypeService.findAllEnabled(0, 50).toPromise().catch(error => {
            this.isLoad = false;
            this.loading = false;
            this.addErrorMessage(error);
            return null;
        });

        if (!ObjectUtil.isNull(chargeTypePage) && !ArrayUtil.isEmpty(chargeTypePage.content)) {
            this.chargeTypeOriginalList = chargeTypePage.content;
            this.chargeTypeList = await this.filterChargeTypeByDefaultTypes(chargeTypePage.content);
            this.sortChargeType();
        }

        return Promise.resolve();
    }

    private sortChargeType(): void {
        this.chargeTypeList.sort((a, b) => {
            const lFieldA: string | number = a.description;
            const lFieldB: string | number = b.description;
            return this.compareSort(lFieldA, lFieldB, true);
        });
    }

    private compareSort(a: number | string, b: number | string, isAsk: boolean): number {
        return (a < b ? -1 : 1) * (isAsk ? 1 : -1);
    }

    private async filterChargeTypeByDefaultTypes(chargeTypeList: FinancialChargeType[]): Promise<FinancialChargeType[]> {
        let lChargeTypeList: FinancialChargeType[] = [];
        if (this.isCashier) {
            const existBankAccountPix: boolean = await this._cashierShiftService.existCashierBankAccountByChargeType(this.receivement.shift, 'PIX');
            const existBankAccountDeposit: boolean = await this._cashierShiftService.existCashierBankAccountByChargeType(this.receivement.shift, 'DEPOSIT');
            const existBankAccountTransfer: boolean = await this._cashierShiftService.existCashierBankAccountByChargeType(this.receivement.shift, 'TRANSFER');
            const existBankAccountOther: boolean = await this._cashierShiftService.existCashierBankAccountByChargeType(this.receivement.shift, 'OTHER');

            lChargeTypeList = chargeTypeList.filter(ct => ct.type === 'CREDIT_CARD' || ct.type === 'DEBIT_CARD' || ct.type === 'CHECK');

            if ((existBankAccountPix && existBankAccountDeposit && existBankAccountTransfer && existBankAccountOther) || (existBankAccountOther)) {
                lChargeTypeList.push(...chargeTypeList.filter(ct => ct.type === 'PIX' || ct.type === 'DEPOSIT' || ct.type === 'TRANSFER' || ct.type === 'MONEY'));
                this.receivement.chargeType = lChargeTypeList.find(ct => ct.type === 'MONEY');
                this.validateBankAccountByCashierShift(this.receivement.chargeType);
            } else {
                if (existBankAccountPix) {
                    lChargeTypeList.push(chargeTypeList.find(ct => ct.type === 'PIX'));
                }
                if (existBankAccountDeposit) {
                    lChargeTypeList.push(chargeTypeList.find(ct => ct.type === 'DEPOSIT'));
                }
                if (existBankAccountTransfer) {
                    lChargeTypeList.push(chargeTypeList.find(ct => ct.type === 'TRANSFER'));
                }
                if (existBankAccountOther) {
                    lChargeTypeList.push(chargeTypeList.find(ct => ct.type === 'MONEY'));
                }

                this.receivement.chargeType = lChargeTypeList[0];
                this.validateBankAccountByCashierShift(this.receivement.chargeType);
            }
            return lChargeTypeList;
        } else {
            this.receivement.chargeType = chargeTypeList.find(ct => ct.type === 'MONEY');

            return chargeTypeList.filter(ct =>
                ct.type === 'CREDIT_CARD'
                || ct.type === 'DEBIT_CARD'
                || ct.type === 'CHECK'
                || ct.type === 'PIX'
                || ct.type === 'DEPOSIT'
                || ct.type === 'TRANSFER'
                || ct.type === 'MONEY'
            );
        }
    }

    validateBankAccountByCashierShift(chargeType?: FinancialChargeType): void {
        try {
            if (this.isCashier) {
                this.bankAccountList = [];
                let lBankAccountList: FinancialBankAccount[] = [];

                if ((chargeType.type === 'PIX') || (chargeType.type === 'DEPOSIT') || (chargeType.type === 'TRANSFER')) {
                    lBankAccountList = this.receivement.shift.cashier.cashierBankAccountList
                        .filter(cba => cba.chargeType.type === chargeType.type)
                        .map(cba => cba.bankAccount);
                }

                // Se não tiver nenhuma configuração de conta bancária para o tipo de cobrança selecionado ou for diferente de PIX, DEPOSIT, e TRANSFER ele pega a outros.
                if (ArrayUtil.isEmpty(lBankAccountList)) {
                    lBankAccountList = this.receivement.shift.cashier.cashierBankAccountList
                        .filter(cba => cba.chargeType.type === 'OTHER')
                        .map(cba => cba.bankAccount);

                    // Se não tiver nenhuma configuração de conta bancária para o tipo de cobrança outros ele mostra a mensagem para que seja configurado.
                    if (ArrayUtil.isEmpty(lBankAccountList)) {
                        const message: string = (this.clientCreditBalance > 0)
                            ? 'É possível realizar o recebimento somente com o crédito do cliente, pois não existe conta bancária configurada no caixa'
                            : 'Não é possível realizar recebimento, pois não existe conta bancária configurada no caixa';

                        this.addWarningMessage(message);
                        return;
                    } else {
                        this.bankAccountList = lBankAccountList;
                    }
                } else {
                    this.bankAccountList = lBankAccountList;
                }

                if (!ArrayUtil.isEmpty(this.bankAccountList) && this.bankAccountList.length === 1) {
                    this.receivement.bankAccount = ObjectUtil.clone(this.bankAccountList[0]);
                }
            }
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    setAmountNet(): void {
        this.validateAdditionValues();

        let amountNetAux: number = this.installmentList.reduce((sum, billInstallment) => sum += billInstallment.amount, 0);
        if (NumberUtil.isPositive(this.receivement.fine)) {
            amountNetAux += this.receivement.fine;
        }
        if (NumberUtil.isPositive(this.receivement.interest)) {
            amountNetAux += this.receivement.interest;
        }
        if (NumberUtil.isPositive(this.receivement.addition)) {
            amountNetAux += this.receivement.addition;
        }
        if (NumberUtil.isPositive(this.receivement.discount)) {
            amountNetAux -= this.receivement.discount;
        }

        this.receivement.amountReceivable = Number(amountNetAux.toFixed(2));
        this.amountNet = Number(amountNetAux.toFixed(2));
        this.setAmountReceived();
    }

    setAmountReceived(resetAmountReceived: boolean = true): void {
        this.receivement.amountReceived = resetAmountReceived ? 0 : this.receivement.amountReceived;
        if (this.receivement.amountReceivable > this.receivement.usedCustomerCredit) {
            this.receivement.amountReceived = Number((this.receivement.amountReceivable - this.clientCredit).toFixed(2));
        }

        if (this.receivement.amountReceivable > this.amountNet) {
            this.useClientCredit = false;
            this.clientCredit = 0;
            this.receivement.customerCreditAmount = Number((this.receivement.amountReceivable - this.amountNet).toFixed(2));
        }
    }

    setClientCredit(): void {
        setTimeout(() => {
            if (this.useClientCredit) {
                if (this.receivement.amountReceivable <= this.clientCreditBalance) {
                    this.clientCredit = this.receivement.amountReceivable;
                } else {
                    this.clientCredit = this.clientCreditBalance;
                }
                this.setAmountReceived();
            } else {
                this.clientCredit = 0;
                this.setAmountReceived();
            }
        });
    }

    getMaxClientCredit(): number {
        if (this.clientCreditBalance < this.receivement.amountReceivable) {
            return this.clientCreditBalance;
        } else {
            if (this.receivement.amountReceivable < this.clientCredit) {
                if (this.receivement.amountReceivable <= this.amountNet) {
                    return this.receivement.amountReceivable;
                } else {
                    return this.amountNet;
                }
            } else {
                return this.amountNet;
            }
        }
    }

    async confirm(): Promise<void> {
        try {
            this.isLoad = true;
            this.loading = true;

            const receivementDate: Date = new Date(moment(this.receivement.receivementDate).format('yyyy/MM/DD'));
            const currentDate: Date = new Date(moment(new Date()).format('yyyy/MM/DD'));
            if (ObjectUtil.isNull(this.receivement.receivementDate)) {
                throw new Error(`Campo data de recebimento é obrigatório e não foi informado`);
            }
            if (!DateUtil.isValid(receivementDate)) {
                throw new Error(`Data de recebimento inválida`);
            }
            if (DateUtil.isGreaterThan(receivementDate, currentDate)) {
                throw new Error(`Data de recebimento (${DateUtil.format(receivementDate, DateUtil.DATE_FORMAT)}) não pode ser maior que a data atual (${DateUtil.format(currentDate, DateUtil.DATE_FORMAT)})`);
            }

            const cashierShiftOpened: boolean = await this.validateCashierOpened();
            if (!cashierShiftOpened) {
                throw new Error('Não é possivel realizar o recebimento. Operador de caixa deve abrir um turno');
            }

            this.setAmountReceived(false);
            this.receivement.usedCustomerCredit = this.clientCredit;

            this.validate();

            await this.validatePostClientCredit();

            this.receivement.fineChangedOnReceipt = this.originalFineAmount !== this.receivement.fine;
            this.receivement.interestChangedOnReceipt = this.originalInterestAmount !== this.receivement.interest;

            if (this.receivement.chargeType.type === 'CHECK') {
                await this._openReceivementCheckDialog().catch(error => {
                    throw new Error(error);
                });
            } else if (this.receivement.chargeType.type === 'DEPOSIT') {
                await this._openReceivementDepositDialog().catch(error => {
                    throw new Error(error);
                });
            } else if (this.receivement.chargeType.type === 'CREDIT_CARD' || this.receivement.chargeType.type === 'DEBIT_CARD') {
                await this._openReceivementCardDialog().catch(error => {
                    throw new Error(error);
                });
            } else if (this.receivement.chargeType.type === 'PIX') {
                if (!ObjectUtil.isNull(this.receivement.bankAccount) && this.receivement.bankAccount.pixConfigured) {
                    await this._openReceivementPixDialog().catch(error => {
                        throw new Error(error);
                    });
                } else {
                    await this._receiveDefault().catch(error => {
                        throw new Error(error);
                    });
                }
            } else if (this.receivement.chargeType.type === 'MONEY' || this.receivement.chargeType.type === 'TRANSFER') {
                await this._receiveDefault().catch(error => {
                    throw new Error(error);
                });
            }
        } catch (e) {
            this.receivement.usedCustomerCredit = 0;
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    async validatePostClientCredit(): Promise<void> {
        if (this.receivement.amountReceivable > this.amountNet) {
            this.receivement.customerCreditAmount = 0;
            if (this.postClientCredit) {
                this.receivement.customerCreditAmount = Number((this.receivement.amountReceivable - this.amountNet).toFixed(2));
            }
        }

        return Promise.resolve();
    }

    validateAdditionValues(): void {
        // Faz isso pois os campos são obrigatórios, mais é permitido passar 0. Então se o campo tiver null já seta 0 no atributo.
        this.receivement.interest = (ObjectUtil.isNull(this.receivement.interest) ? 0 : this.receivement.interest);
        this.receivement.fine = (ObjectUtil.isNull(this.receivement.fine) ? 0 : this.receivement.fine);
        this.receivement.discount = (ObjectUtil.isNull(this.receivement.discount) ? 0 : this.receivement.discount);
    }

    validate(): void {
        this.validateAdditionValues();

        if ((this.receivement.amountReceivable > this.amountNet) && (this.postClientCredit) && (this.useClientCredit)) {
            throw new Error('Não é possível aproveitar o crédito do cliente e lançar o valor do troco como crédito para o cliente no mesmo recebimento');
        }
        if ((this.receivement.amountReceivable > this.amountNet)
            && (this.postClientCredit)
            && (!this.useClientCredit)
            && (this.receivement.chargeType.type === 'CREDIT_CARD' || this.receivement.chargeType.type === 'DEBIT_CARD')) {
            const typeChargeType: string = (this.receivement.chargeType.type === 'CREDIT_CARD') ? 'cartão de crédito' : 'cartão de débito';
            throw new Error(`Não é possível lançar o valor do troco como crédito para o cliente com o tipo de cobrança ${typeChargeType}`);
        }
        const receivementDate: Date = new Date(moment(this.receivement.receivementDate).format('yyyy/MM/DD'));
        const currentDate: Date = new Date(moment(new Date()).format('yyyy/MM/DD'));
        if (ObjectUtil.isNull(this.receivement.receivementDate)) {
            throw new Error(`Campo data de recebimento é obrigatório e não foi informado`);
        }
        if (!DateUtil.isValid(receivementDate)) {
            throw new Error(`Data de recebimento inválida`);
        }
        if (DateUtil.isGreaterThan(receivementDate, currentDate)) {
            throw new Error(`Data de recebimento (${DateUtil.format(receivementDate, DateUtil.DATE_FORMAT)}) não pode ser maior que a data atual (${DateUtil.format(currentDate, DateUtil.DATE_FORMAT)})`);
        }
        if ((NumberUtil.isPositive(this.receivement.interest) || NumberUtil.isPositive(this.receivement.fine)) && NumberUtil.isPositive(this.receivement.discount)) {
            throw new Error('Digite apenas juros e multa ou desconto');
        }
        // const amountNetAux: number = this.installmentList.reduce((sum, billInstallment) => sum += billInstallment.amount, 0);
        if (this.receivement.discount > Number(this.amountNet.toFixed(2))) {
            throw new Error('Desconto não pode ser maior que o valor liquído');
        }
        if (this.receivement.discount === Number(this.amountNet.toFixed(2))) {
            throw new Error('Desconto não pode ser igual ao valor liquído');
        }
        if (this.receivement.amountReceivable < Number(this.amountNet.toFixed(2))) {
            if (NumberUtil.isPositive(this.receivement.discount)) {
                throw new Error('Não é possivel realizar recebimento parcial com desconto');
            }
            if (NumberUtil.isPositive(this.receivement.addition)) {
                throw new Error('Não é possivel realizar recebimento parcial com acrécimo');
            }
        }
        if (this.receivement.amountReceivable < this.clientCredit) {
            throw new Error('Não é possivel realizar recebimento onde o crédito do cliente seja maior que o valor a receber');
        }
        if (NumberUtil.isNegative(this.receivement.amountReceived)) {
            throw new Error('Não é possivel realizar recebimento onde o total recebido é negativo');
        }
        if (ObjectUtil.isNull(this.receivement.amountReceivable)) {
            throw new Error('Campo valor a receber é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNull(this.receivement.chargeType) || ObjectUtil.isNewModel(this.receivement.chargeType)) {
            throw new Error('Campo tipo de cobrança é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNull(this.receivement.bankAccount) || ObjectUtil.isNewModel(this.receivement.bankAccount)) {
            throw new Error('Campo conta bancária é obrigatório e não foi informado');
        }
        if ((this.useClientCredit && !NumberUtil.isPositive(this.receivement.usedCustomerCredit))
            || (this.receivement.usedCustomerCredit !== this.receivement.amountReceivable && !NumberUtil.isPositive(this.receivement.amountReceived))) {
            throw new Error('Abater crédito do cliente foi selecionado, portanto não foi informado o valor a ser abatido');
        }
        if (this.isCashier && ObjectUtil.isNewModel(this.receivement.shift)) {
            throw new Error('Não é possivel realizar o recebimento. Operador de caixa deve abrir um turno');
        }
    }

    private async _openReceivementDepositDialog(): Promise<void> {
        const receivementDepositData: ReceivementDepositData = {
            receivement: ObjectUtil.clone(this.receivement),
            installmentList: this.installmentList,
            isCashier: this.isCashier,
            useClientCredit: this.useClientCredit
        };

        await this._dialogService.open({
            componentOrTemplateRef: ReceivementDepositDialogComponent,
            data: receivementDepositData,
            width: '40%',
        }).afterClosed().toPromise().then((saved: boolean) => {
            if (saved) {
                this.close('RELOAD_TABLE', receivementDepositData.receivement.receivementDate, this.printReceipt);
            } else {
                this.loading = false;
                this.isLoad = false;
            }
        }, error => {
            this.loading = false;
            this.isLoad = false;
            return Promise.reject(error);
        });
    }

    private async _openReceivementCardDialog(): Promise<void> {
        const receivementCardData: ReceivementCardData = {
            receivement: ObjectUtil.clone(this.receivement),
            installmentList: this.installmentList,
            isCashier: this.isCashier,
            useClientCredit: this.useClientCredit
        };

        await this._dialogService.open({
            componentOrTemplateRef: ReceivementCardDialogComponent,
            data: receivementCardData,
            width: '65%'
        }).afterClosed().toPromise().then((saved: boolean) => {
            if (saved) {
                this.close('RELOAD_TABLE', receivementCardData.receivement.receivementDate, this.printReceipt);
            } else {
                this.loading = false;
                this.isLoad = false;
            }
        }, error => {
            this.loading = false;
            this.isLoad = false;
            return Promise.reject(error);
        });
    }

    private async _openReceivementCheckDialog(): Promise<void> {
        const installmentList: FinancialBillInstallment[] = this.installmentList.filter(b => {
            if (!ObjectUtil.isNewModel(b.bill.client.person.legalPerson) && StringUtil.isEmpty(b.bill.client.person.legalPerson.cnpj)) {
                return b;
            } else if (!ObjectUtil.isNewModel(b.bill.client.person.naturalPerson) && StringUtil.isEmpty(b.bill.client.person.naturalPerson.cpf)) {
                return b;
            }
        });

        if (!ArrayUtil.isEmpty(installmentList)) {
            this.loading = false;
            this.isLoad = false;
            return Promise.reject('Não é possível realizar recebimento por cheque pois o cadastro do cliente está incompleto');
        }

        const receivementCheckData: ReceivementCheckData = {
            receivement: ObjectUtil.clone(this.receivement),
            installmentList: this.installmentList,
            isCashier: this.isCashier,
            useClientCredit: this.useClientCredit
        };

        const height: string = this._breakpointObserver.isMatched('(max-width: 1366px)') ? '100vh' : 'auto';

        await this._dialogService.open({
            componentOrTemplateRef: ReceivementCheckDialogComponent,
            data: receivementCheckData,
            width: '75%',
            height: height,
        }).afterClosed().toPromise().then((saved: boolean) => {
            if (saved) {
                this.close('RELOAD_TABLE', receivementCheckData.receivement.receivementDate, this.printReceipt);
            } else {
                this.loading = false;
                this.isLoad = false;
            }
        }, error => {
            this.loading = false;
            return Promise.reject(error);
        });
    }

    private async _openReceivementPixDialog(): Promise<void> {
        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        const isValid = (
            !ArrayUtil.isEmpty(this.installmentList) &&
            !ObjectUtil.isNull(this.installmentList[0].bill) &&
            !ObjectUtil.isNull(this.installmentList[0].bill.client) &&
            !ObjectUtil.isNull(this.installmentList[0].bill.client.person)
        );

        let payerName = '';
        if (isValid) {
            payerName = this.installmentList[0].bill.client.person.name;
            if (configuration && configuration.showBusinessNameInReceiptQuery) {
                payerName = this.installmentList[0].bill.client.person.fantasyName;
            }
        }

        const data: PixDialogData = {
            bankAccountId: this.receivement.bankAccount.id,
            payerName: payerName,
            value: this.receivement.amountReceivable,
        };

        this._dialogService.open({
            componentOrTemplateRef: PixDialogComponent,
            data: data,
            width: '30%',
            panelClass: 'no-scrolls-dialog'
        }).afterClosed().toPromise().then((isConfirmed: boolean) => {
            if (isConfirmed) {
                this._receiveDefault();
            } else {
                this.loading = false;
                this.isLoad = false;
            }
        }, error => {
            this.loading = false;
            return Promise.reject(error);
        });
    }

    private async _receiveDefault(): Promise<void> {
        this.loading = true;

        await this._receivementService.receive(this.receivement, this.installmentList, [], this.useClientCredit).toPromise().then(() => {
            this.receivement.amountNet = this.amountNet;
            this.close('RELOAD_TABLE', this.receivement.receivementDate, this.printReceipt);
        }, error => {
            this.loading = false;
            this.isLoad = false;
            return Promise.reject(error);
        });
    }

    validateReceivementDate(): void {
        try {
            if (BrowserUtil.getBrowser() === 'Firefox') {
                this.loading = false;
                this.isLoad = false;
                return;
            }

            const receivementDate: Date = new Date(moment(this.receivement.receivementDate).format('yyyy/MM/DD'));
            const currentDate: Date = new Date(moment(new Date()).format('yyyy/MM/DD'));
            if (ObjectUtil.isNull(this.receivement.receivementDate)) {
                throw new Error(`Campo data de recebimento é obrigatório e não foi informado`);
            }
            if (!DateUtil.isValid(receivementDate)) {
                throw new Error(`Data de recebimento inválida`);
            }
            if (DateUtil.isGreaterThan(receivementDate, currentDate)) {
                throw new Error(`Data de recebimento (${DateUtil.format(receivementDate, DateUtil.DATE_FORMAT)}) não pode ser maior que a data atual (${DateUtil.format(currentDate, DateUtil.DATE_FORMAT)})`);
            }
        } catch (e) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE', receivementDate?: Date, printReceipt?: boolean): void {
        this.dialogRef.close({ operation, receivementDate, printReceipt });
    }
}
