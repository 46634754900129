import { Injectable } from '@angular/core';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractService, GIPIBaseService, GIPIUuid, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPixReturnDTO } from '../models/dto/pix-return.dto';

@Injectable({ providedIn: 'root' })
export class FinancialPixService extends GIPIAbstractService {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.pix, 'v1');
    }

    cancel(bankAccountId: GIPIUuid, txId: string, version?: string): Observable<FinancialPixReturnDTO> {
        if (!UUIDUtil.isValid(bankAccountId)) {
            throw new Error('Id da conta bancária é obrigatório e não foi informado');
        }
        if (StringUtil.isEmpty(txId)) {
            throw new Error('txId é obrigatório e não foi informado');
        }

        return this.baseService.httpClient.post(this.url(`cancel?bankAccountId=${bankAccountId}&txId=${txId}`, version), null).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    consult(bankAccountId: GIPIUuid, txId: string, version?: string): Observable<FinancialPixReturnDTO> {
        if (!UUIDUtil.isValid(bankAccountId)) {
            throw new Error('Id da conta bancária é obrigatório e não foi informado');
        }
        if (StringUtil.isEmpty(txId)) {
            throw new Error('txId é obrigatório e não foi informado');
        }

        return this.baseService.httpClient.get(this.url(`consult?bankAccountId=${bankAccountId}&txId=${txId}`, version)).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    generate(bankAccountId: GIPIUuid, value: number, version?: string): Observable<FinancialPixReturnDTO> {
        if (!UUIDUtil.isValid(bankAccountId)) {
            throw new Error('Id da conta bancária é obrigatório e não foi informado');
        }
        if (!value) {
            throw new Error('Valor é obrigatório e não foi informado');
        }

        return this.baseService.httpClient.post(this.url('generate', version), { bankAccountId: bankAccountId, value: value }).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
