<gipi-abstract-form id="dialogPix"
                    name="dialogPix"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogPix"
                  label="Pix cobrança"
                  [class]="'pix-dialog-toolbar'">
        <button matTooltip="Imprimir"
                class="btn-print-qr-code"
                type="button"
                *ngIf="!(pixConfirmed$ | async) && !(timeExpired$ | async) && pixReturn?.qrCode && !loading"
                [disabled]="loading"
                (click)="printQrCode()">
            <mat-icon svgIcon="print_bank_slip"> </mat-icon>
            Imprimir
        </button>
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div class="qr-code-container">
            <ng-container *ngIf="!(pixConfirmed$ | async) && !(timeExpired$ | async) && pixReturn?.qrCode && !loading">
                <gipi-qr-code #QRCodeRef
                              [qrdata]="pixReturn?.qrCode"
                              [width]="(pixReturn?.pixType === 'DYNAMIC') ? 180 : 200">
                </gipi-qr-code>

                <div *ngIf="(pixReturn?.pixType === 'DYNAMIC')"
                     class="qr-code-time">
                    <div class="expiration-date">
                        <strong> Válido até: </strong>
                        <span> {{ (expirationDate$ | async) }} </span>
                    </div>
                    <div class="expiration-time">
                        <div class="minutes">
                            <div class="first"> {{ firstMinute }} </div>
                            <div class="second"> {{ secondMinute }} </div>
                        </div>
                        :
                        <div class="seconds">
                            <div class="first"> {{ firstSecond }} </div>
                            <div class="second"> {{ secondSecond }} </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <mat-icon *ngIf="(timeExpired$ | async) || !pixReturn?.qrCode"
                          svgIcon="pix_warning">
                </mat-icon>

                <mat-icon *ngIf="(pixConfirmed$ | async)"
                          svgIcon="pix_success">
                </mat-icon>

                <div *ngIf="(pixConfirmed$ | async) || (timeExpired$ | async) || !pixReturn?.qrCode"
                     class="qr-code-status"
                     [class.warning]="!(pixConfirmed$ | async)"
                     [class.success]="(pixConfirmed$ | async)">
                    <strong> {{ ( infoTitleStatus$ | async) }} </strong>
                    <span> {{ ( infoDescriptionStatus$ | async) }} </span>
                </div>
            </ng-container>

            <mat-progress-spinner *ngIf="loading"
                                  mode="indeterminate"
                                  color="primary">
            </mat-progress-spinner>
        </div>

        <div class="divisory"> </div>

        <div class="qr-code-payment-info">
            <div class="row">
                <strong> Pix copia e cola: </strong>
                <span> {{ pixReturn?.qrCode }} </span>
                <button matTooltip="Copiar"
                        class="btn-copy-qr-code"
                        type="button"
                        *ngIf="!(timeExpired$ | async) && !(pixConfirmed$ | async) && pixReturn?.qrCode && !loading"
                        [disabled]="loading"
                        (click)="copyQrCode()">
                    <mat-icon> content_copy </mat-icon>
                    Copiar
                </button>
            </div>
            <div class="row">
                <strong> Recebedor: </strong>
                <span> {{ pixReturn?.receiverName }} </span>
            </div>
            <div class="row">
                <strong> Valor: </strong>
                <span> {{ pixReturn?.value | currency:'BRL':'symbol':'1.2-2' }} </span>
            </div>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Atualizar"
                     gipi-primary
                     id="btnSave"
                     *ngIf="(timeExpired$ | async) && !(pixConfirmed$ | async)"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="refreshQrCode()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     *ngIf="!(timeExpired$ | async) && pixReturn?.qrCode"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Cancelar"
                     gipi-secondary
                     id="btnReturn"
                     *ngIf="!(pixConfirmed$ | async)"
                     (click)="close(false)"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<iframe #iFrameQRCodePDF
        style="display: none; border: none;">
</iframe>