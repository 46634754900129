export enum SaleScanntechPromotionTypeEnum {
    ADICIONAL_DESCUENTO = 'Adicional Desconto',
    ADICIONAL_REGALO = 'Adicional Presente',
    DESCUENTO_FIJO = 'Desconto Fixo',
    DESCUENTO_VARIABLE = 'Desconto Variável',
    LLEVA_PAGA = 'Leva e Paga',
    PRECIO_FIJO = 'Preço Fixo',
}

export type SaleScanntechPromotionType = (
    | 'ADICIONAL_DESCUENTO'
    | 'ADICIONAL_REGALO'
    | 'DESCUENTO_FIJO'
    | 'DESCUENTO_VARIABLE'
    | 'LLEVA_PAGA'
    | 'PRECIO_FIJO'
);
