import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { NotificationPaths } from '@gipi-paths/notification.paths';
import { ArrayUtil, GIPIAbstractService, GIPIBaseService, GIPIPageModel } from '@gipisistemas/ng-core';
import { NotificationNotifyWhatsappDTO } from '../models/dto/notifiy-whatsapp.dto';
import { NotificationNotifyWhatsappConsultDTO } from '../models/dto/notify-whatsapp-consult.dto';
import { NotificationNotifyWhatsappFilterDTO } from '../models/dto/notify-whatsapp-filter.dto';
import { NotificationNotifyWhatsappTotalizationConsultDTO } from '../models/dto/notify-whatsapp-totalization-consult.dto';
import { NotificationNotifyWhatsappTotalizationFilterDTO } from '../models/dto/notify-whatsapp-totalization-filter.dto';

@Injectable({ providedIn: 'root' })
export class NotificationNotifyWhatsappService extends GIPIAbstractService {

    constructor(protected baseService: GIPIBaseService) {
        super(NotificationPaths.notification, 'v1');
    }

    notificationWhatsapp(filter: NotificationNotifyWhatsappFilterDTO, version?: string): Observable<GIPIPageModel<NotificationNotifyWhatsappConsultDTO>> {
        return this.baseService.httpClient.post(this.url('notification-whatsapp', version), filter, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    notificationWhatsappTotalization(filter: NotificationNotifyWhatsappTotalizationFilterDTO, version?: string): Observable<NotificationNotifyWhatsappTotalizationConsultDTO> {
        return this.baseService.httpClient.post(this.url('notification-whatsapp-totalization', version), filter, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    notifyWhatsapp(notificationList: NotificationNotifyWhatsappDTO[], version?: string): Observable<void> {
        if (ArrayUtil.isEmpty(notificationList)) {
            throw new Error('Informe ao menos uma mensagem à ser enviada');
        }

        return this.baseService.httpClient.post(this.url(`notify-whatsapp`, version), notificationList, this.options()).pipe(
            map(_ => true),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
