import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { FinancialAddress } from '@gipi-financial/person/models/address.model';
import { FinancialPixReturnDTO } from '@gipi-financial/pix/models/dto/pix-return.dto';
import { GIPIAbstractComponent, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'gipi-pix-voucher-pdf',
    template: `
        <div class="receipt">
        	<h1>{{ companyName }}</h1>
        	<p class="info">{{ companyAddress }}</p>
        	<div class="divider"></div>
        	<div class="details">
        		<p>Chave Pix:
        			<strong>{{ pixReturn?.pixKey }}</strong>
        		</p>
        		<p>Recebedor:
        			<strong>{{ pixReturn?.receiverName }}</strong>
        		</p>
        		<p>Valor:
        			<strong>{{ pixReturn?.value | currency:'BRL':'symbol':'1.2-2' }}</strong>
        		</p>
        	</div>
        	<div class="qr-code">
        		<gipi-qr-code #QRCodeRef [qrdata]="pixReturn?.qrCode" [width]="200"></gipi-qr-code>
        	</div>
        	<p class="payment-deadline" *ngIf="pixReturn?.pixType === 'DYNAMIC'">Prazo para pagamento
        		<br>
        			<strong>{{ expirationDate }}</strong>
        		</p>
        	</div>`,
})
export class PixVoucherPdfComponent extends GIPIAbstractComponent implements OnInit {

    public company: FinancialCompany = null;
    public pixReturn: FinancialPixReturnDTO = null;

    public get expirationDate(): string {
        const expirationDate: moment.Moment = moment().add(this.pixReturn.expirationInSeconds, 'seconds');
        return expirationDate.format('DD/MM/YYYY HH:mm:ss');
    }

    public get companyName(): string {
        if (ObjectUtil.isNull(this.company)) {
            return '';
        }
        return (
            !StringUtil.isEmpty(this.company.person.fantasyName)
                ? this.company.person.fantasyName
                : this.company.person.name
        );
    }

    public get companyAddress(): string {
        if (ObjectUtil.isNull(this.company)) {
            return '';
        }

        let companyDoc: string = '';
        if (!ObjectUtil.isNull(this.company.person.legalPerson)) {
            companyDoc = 'CNPJ: ' + StringUtil.format(this.company.person.legalPerson.cnpj, '00.000.000/0000-00')
        } else if (!ObjectUtil.isNull(this.company.person.naturalPerson)) {
            companyDoc = 'CPF: ' + StringUtil.format(this.company.person.naturalPerson.cpf, '000.000.000-00');
        }

        const address: FinancialAddress = this.company.person.addressList.find(e => e.useInInvoice);
        if (!ObjectUtil.isNull(address)) {
            const addressZipCode: string = StringUtil.format(address.zipCode, '00000-000');
            return `${address.street} ${address.neighborhood}, ${address.city.name}-${address.city.state.acronym}, ${addressZipCode}, ${companyDoc}`;
        }
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void { }

}
