import { FinancialCostCenterAssessment } from '@gipi-financial/cost-center-assessment/models/cost-center-assessment.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialPostingCategoryOperation } from '../enums/operation.enum';
import { FinancialPostingCategoryGroupEnum } from '../enums/posting-category-group.enum';

export class FinancialPostingCategory extends GIPIBaseAbstractModel {

    upperCategory: FinancialPostingCategory;

    children: FinancialPostingCategory[];

    code: number;

    description: string;

    operation: FinancialPostingCategoryOperation = 'DEBIT';

    standardsOfPosting: boolean = false;

    postingDescription: string;

    costCenterAssessment: FinancialCostCenterAssessment;

    fullCode: string;

    hasChildren: boolean = false;

    postingCategoryGroup: FinancialPostingCategoryGroupEnum | string;

    registrationPostingCategoryId: GIPIUuid;

    /** @Transient */
    isMouseHover: boolean = false;

    constructor(id?: GIPIUuid) {
        super();
        this.id = id;
    }

}
