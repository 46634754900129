import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { NotificationCustomerNotificationConfig } from '@gipi-notification/customer-notification-config/models/customer-notification-config.model';
import { NotificationCustomerNotificationConfigService } from '@gipi-notification/customer-notification-config/services/customer-notification-config.service';
import { NotificationNotifyWhatsappTotalizationConsultDTO } from '@gipi-notification/notify-whatsapp/models/dto/notify-whatsapp-totalization-consult.dto';
import { NotificationNotifyWhatsappTotalizationFilterDTO } from '@gipi-notification/notify-whatsapp/models/dto/notify-whatsapp-totalization-filter.dto';
import { NotificationNotifyWhatsappService } from '@gipi-notification/notify-whatsapp/services/notify-whatsapp.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { APP_MESSAGES, ArrayUtil, EmailUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, MonthYear, ObjectUtil, StringUtil, TokenDTO } from '@gipisistemas/ng-core';

type TwilioOptions = {
    id: string;
    key: string;
    title: string;
    description: string;
    selected: boolean;
};

@Component({
    templateUrl: './integration-whatsapp-configuration-dialog.component.html',
    styleUrls: ['./integration-whatsapp-configuration-dialog.component.scss'],
})
export class IntegrationWhatsappConfigurationDialogComponent extends GIPIAbstractComponent implements OnInit {

    private _now: Date = new Date()

    public customerNotificationConfig: NotificationCustomerNotificationConfig = new NotificationCustomerNotificationConfig();

    public panelTwilioOptions: TwilioOptions[] = [
        // { key: 'accountPayableNotificationEnabled', title: 'Contas a pagar', description: 'Envie mensagens em massa na tela contas a pagar.', selected: false },
        { id: 'panelTwilioAccountReceivable', key: 'accountReceivableNotificationEnabled', title: 'Contas a receber', description: 'Envie mensagens em massa na tela contas a receber.', selected: false },
        { id: 'panelTwilioBirthday', key: 'birthdayNotificationEnabled', title: 'Aniversários', description: 'Envie mensagens para aniversariantes do mês.', selected: false },
        { id: 'panelTwilioInactiveCustomer', key: 'potentialClientNotificationEnabled', title: 'Clientes potenciais', description: 'Envie mensagens para clientes que nunca compraram.', selected: false },
        { id: 'panelTwilioBilletKey', key: 'billetKeyNotificationEnabled', title: 'Boletos', description: 'Envie mensagens com chaves de pagamento de boletos.', selected: false },
        // { key: 'billetReminderNotificationEnabled', title: 'Lembretes de pagamento', description: 'Envie lembretes de pagamento via WhatsApp.', selected: false },
        // { key: 'promotionsAndSuggestionsNotificationEnabled', title: 'Promoções', description: 'Envie mensagens promocionais para os clientes.', selected: false },
    ];

    public panelTwilioPeriodicity: MonthYear = new MonthYear(this._now.getMonth(), this._now.getFullYear());

    public isClosedInvoiceTwilio: boolean = false;

    public panelTwilioNotifyWhatsappTotalization: NotificationNotifyWhatsappTotalizationConsultDTO = null;

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _authenticationService: CustomAuthenticationService,
        private _companyService: FinancialCompanyService,
        private _customerNoficationConfigService: NotificationCustomerNotificationConfigService,
        private _notifyWhatsappService: NotificationNotifyWhatsappService,
        public dialogRef: MatDialogRef<IntegrationWhatsappConfigurationDialogComponent>,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
        this._findCustomerNotificationConfig();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    private _findCustomerNotificationConfig(): void {
        try {
            this.loading = true;
            this._customerNoficationConfigService.findAllEnabled<NotificationCustomerNotificationConfig>(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.customerNotificationConfig = ObjectUtil.clone(page.content[0]);

                    for (const option of this.panelTwilioOptions) {
                        option.selected = this.customerNotificationConfig[option.key];
                    }

                    this.findNotificationWhatsappTotalization();
                } else {
                    this._setCnpjCompany();
                }
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                this.addErrorMessage(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _setCnpjCompany(): void {
        if (ObjectUtil.isNull(this.customerNotificationConfig)) {
            return;
        }

        const token: TokenDTO<OAuthUser> = this._authenticationService.token;
        if (this._authenticationService.isValidToken(token)) {
            this._companyService.getOne<FinancialCompany>(token.user.currentCompany).subscribe(company => {
                if (!ObjectUtil.isNull(company) && !ObjectUtil.isNull(company.person)) {
                    const cpfOrCnpj: string = !ObjectUtil.isNull(company.person.legalPerson)
                        ? company.person.legalPerson.cnpj
                        : company.person.naturalPerson.cpf;

                    this.customerNotificationConfig.cnpj = cpfOrCnpj;

                    if (!ArrayUtil.isEmpty(company.person.emailList)) {
                        this.customerNotificationConfig.emailCustomer = company.person.emailList[0].email;
                    }
                }
            }, error => {
                this.loading = false;
                this.addErrorMessage(error);
                return null;
            });
        }
    }

    private _isValid(): boolean {
        if (ObjectUtil.isNull(this.customerNotificationConfig)) {
            return false;
        }
        if (StringUtil.isEmpty(this.customerNotificationConfig.emailCustomer)) {
            this.addWarningMessage('Campo e-mail é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.customerNotificationConfig.phoneNumber)) {
            this.addWarningMessage('Campo telefone é obrigatório e não foi informado');
            return false;
        }

        return true;
    }

    public confirm(): void {
        try {
            if (!this._isValid()) {
                return;
            }
            this.loading = true;

            for (const option of this.panelTwilioOptions) {
                this.customerNotificationConfig[option.key] = option.selected;
            }

            // Passa null pra sempre gerar uma nova configuração
            this.customerNotificationConfig.id = null;
            this.customerNotificationConfig.accountPayableNotificationEnabled = false;
            // this.customerNotificationConfig.accountReceivableNotificationEnabled = false;
            // this.customerNotificationConfig.birthdayNotificationEnabled = false;
            // this.customerNotificationConfig.potentialClientNotificationEnabled = false;
            // this.customerNotificationConfig.billetKeyNotificationEnabled = false;
            this.customerNotificationConfig.billetReminderNotificationEnabled = false;
            this.customerNotificationConfig.promotionsAndSuggestionsNotificationEnabled = false;

            this._customerNoficationConfigService.save(this.customerNotificationConfig).toPromise().then(response => {
                if (!ObjectUtil.isNull(response)) {
                    this.loading = false;
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);

                    if (ObjectUtil.isNull(this._customerNoficationConfigService.updated$)) {
                        this._customerNoficationConfigService.updated$ = new BehaviorSubject(false);
                    }
                    this._customerNoficationConfigService.updated$.next(true);

                    this.close();
                }
            }).catch(e => {
                this.loading = false;
                this.addErrorMessage(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    public validateEmail(email: string): void {
        if (!StringUtil.isEmpty(email) && !EmailUtil.isValid(email)) {
            this.addWarningMessage('O e-mail informado é inválido');
        }
    }

    public findNotificationWhatsappTotalization(): void {
        if (ObjectUtil.isNull(this.customerNotificationConfig)) {
            this.panelTwilioNotifyWhatsappTotalization = null;
            return;
        }

        this.loading = true;
        const invoiceDate: moment.Moment = moment().year(this.panelTwilioPeriodicity.year).month(this.panelTwilioPeriodicity.month);
        if (invoiceDate && invoiceDate.isValid()) {
            const filter: NotificationNotifyWhatsappTotalizationFilterDTO = new NotificationNotifyWhatsappTotalizationFilterDTO();
            filter.startDate = invoiceDate.startOf('month').startOf('day').toDate();
            filter.endDate = invoiceDate.endOf('month').endOf('day').toDate();
            filter.status = this.isClosedInvoiceTwilio ? 'INVOICED' : 'NOT_INVOICED';

            this._notifyWhatsappService.notificationWhatsappTotalization(filter).toPromise()
                .then(notificationStatement => {
                    if (!ObjectUtil.isNull(notificationStatement)) {
                        this.panelTwilioNotifyWhatsappTotalization = notificationStatement;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.handleError(error);
                });
        }
    }

}
