<ng-container *ngIf="!(isAuthenticated$ | async); else mainTemplate">
    <router-outlet> </router-outlet>
</ng-container>

<ng-template #mainTemplate>
    <gipi-top-nav id="topNav">
        <div id="sectionImages">
            <img alt="Logo da GIPI Sistemas"
                 id="bigLogo"
                 src="../assets/logo/logo.svg"
                 width="28"
                 height="40" />
        </div>
        <div id="sectionContent">
            <div id="sectionCompanyList">
                <itss-select-entity-paged id="company"
                                          name="company"
                                          class="select-company"
                                          classSelectPanel="select-company-panel"
                                          svgIconPrefix="company"
                                          [(model)]="company"
                                          [options]="companyList"
                                          [propertyFn]="companyFn"
                                          [showClear]="false"
                                          [hideFieldSearch]="true"
                                          [infiniteScroll]="false"
                                          (selectionChange)="changeCompany($event); clearSessionStorage()">
                </itss-select-entity-paged>
            </div>

            <div id="sectionActions">
                <gipi-novelties tooltip="Novidades"
                                svgIcon="novelties"
                                [hasNovelties]="hasNovelties"
                                (click)="openNoveltiesDialog()">
                </gipi-novelties>

                <gipi-notification tooltip="Notificações"
                                   svgIcon="notification">
                    <strong> Em breve </strong>
                </gipi-notification>

                <gipi-user-profile id="userProfile"
                                   name="userProfile"
                                   arialLabel="Perfil de usuário"
                                   [imgSrc]="userPhotoSrc ? userPhotoSrc : '../assets/logo/user_default.svg'"
                                   [text]="firstName"
                                   [username]="username"
                                   [email]="email"
                                   [lastAccess]="lastAccess"
                                   [svgIconEdit]="'person'"
                                   [svgIconExit]="'logout'"
                                   (onClickEdit)="openUserInformationDialog()"
                                   (onClickExit)="logout()">
                </gipi-user-profile>
            </div>
        </div>
    </gipi-top-nav>

    <gipi-sidenav-container>
        <gipi-sidenav id="sidenav"
                      labelIntegration="Integrações"
                      labelConfiguration="Configurações"
                      svgIconIntegration="integration"
                      svgIconConfiguration="configuration"
                      [showTagNewIntegration]="true"
                      [showConfiguration]="hasPermission('GENERAL_SETTINGS')"
                      [showIntegration]="hasPermission('INTEGRATION_SETTINGS')"
                      [menuItems]="(menuList$ | async)"
                      (onClickMenuIntegration)="onMenuItemIntegrationChange()"
                      (onClickMenuConfiguration)="onMenuItemConfigurationChange()">
        </gipi-sidenav>
        <router-outlet> </router-outlet>
    </gipi-sidenav-container>

    <gipi-footer></gipi-footer>
</ng-template>
