<gipi-abstract-form id="dialogReceivementCardAdministrator"
                    name="dialogReceivementCardAdministrator"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogReceivementCardAdministrator">
        Recebimento de cartão
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-input-currency label="Valor bruto"
                                 id="receivementAmountOriginalValue"
                                 id="receivementAmountOriginalValue"
                                 [disabled]="true"
                                 [ngModel]="receivementAmounts?.originalValue"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Valor taxa"
                                 id="receivementAmountDiscountRate"
                                 name="receivementAmountDiscountRate"
                                 [disabled]="true"
                                 [ngModel]="receivementAmounts?.discountRate"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Valor taxa antecipação"
                                 id="receivementAmountDiscountAticipation"
                                 name="receivementAmountDiscountAticipation"
                                 *ngIf="isAnticipation"
                                 [disabled]="true"
                                 [ngModel]="receivementAmounts?.discountAnticipation"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Valor líquido"
                                 id="receivementAmountAmountNet"
                                 name="receivementAmountAmountNet"
                                 [disabled]="true"
                                 [ngModel]="receivementAmounts?.amountNet"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>

        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <gipi-datepicker label="Data do recebimento"
                             id="receivementDate"
                             name="receivementDate"
                             [required]="true"
                             [disabled]="loading || isViewing()"
                             [(ngModel)]="receivementDate"
                             (ngModelChange)="recalculateFees()"
                             [fxFlex]="!isAnticipation ? 'calc(33.9% - 16px)' : 'calc(25.5% - 16px)'"
                             [style.max-width]="!isAnticipation ? 'calc(33.9% - 16px)' : 'calc(25.5% - 16px)'"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-select-entity-paged label="Categoria"
                                      [required]="true"
                                      [disabled]="loading || isViewing()"
                                      [(model)]="receivementPostingCategory"
                                      [nextBatchFn]="postingCategoryFindByValueFn"
                                      [propertyFn]="postingCategoryFn"
                                      (selectionChange)="postingCategorySelectionChange($event)"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
        </div>

        <gipi-expansion-panel panelTitle="Rateio das taxas"
                              id="panelBillAssessment"
                              svgIcon="assessment"
                              [iconSize]="20"
                              [disabled]="loading || (!receivementAmounts?.discountRate && !receivementAmounts.discountAnticipation)">
            <div fxLayout="column"
                 fxLayoutGap="16px">
                <div fxLayout="row"
                     fxLayoutAlign="start"
                     fxLayoutGap="16px"
                     fxLayout.lt-sm="column">
                    <itss-select-entity-paged label="Centro de custo"
                                              id="panelBillAssessmentCostCenter"
                                              name="panelBillAssessmentCostCenter"
                                              [required]="true"
                                              [disabled]="loading || isViewing()"
                                              [(model)]="assessment.costCenter"
                                              [nextBatchFn]="costCenterFindByValueFn"
                                              [propertyFn]="costCenterFn"
                                              (modelChange)="calculateAssessmentAmount()"
                                              fxFlex
                                              fxFlex.lt-md="100">
                    </itss-select-entity-paged>
                    <itss-input-currency label="Percentual"
                                         id="panelBillAssessmentCostCenter"
                                         name="panelBillAssessmentCostCenter"
                                         [required]="true"
                                         [disabled]="loading"
                                         [(ngModel)]="assessment.percentage"
                                         [options]="{prefix: '', nullable: false, min: 0, max: 100}"
                                         (ngModelChange)="calculateAssessmentAmount()"
                                         fxFlex="25"
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                    <itss-input-currency label="Valor"
                                         [(ngModel)]="assessment.amount"
                                         disabled="true"
                                         fxFlex="25"
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                </div>
                <div fxLayout="row"
                     fxLayoutAlign="end center">
                    <gipi-button label="Adicionar"
                                 gipi-primary
                                 id="btnAddAssessment"
                                 [disabled]="loading"
                                 (click)="addAssessment()"
                                 fxFlex="15"
                                 fxFlex.lt-md="100"
                                 fxLayoutAlign="end">
                    </gipi-button>
                </div>
            </div>

            <itss-table [paginator]="false"
                        [backendPagination]="false"
                        [columns]="costCenterColumns"
                        [data]="assessmentList"
                        [loading]="loading">
                <ng-template #actions
                             let-assessment>
                    <itss-button svgIcon="trash"
                                 [disabled]="loading"
                                 color="warn"
                                 type="icon"
                                 tooltip="Excluir"
                                 (click)="removeAssessment(assessment)">
                    </itss-button>
                </ng-template>
            </itss-table>
        </gipi-expansion-panel>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close('NONE')"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
