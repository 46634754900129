
        <div class="receipt">
        	<h1>{{ companyName }}</h1>
        	<p class="info">{{ companyAddress }}</p>
        	<div class="divider"></div>
        	<div class="details">
        		<p>Chave Pix:
        			<strong>{{ pixReturn?.pixKey }}</strong>
        		</p>
        		<p>Recebedor:
        			<strong>{{ pixReturn?.receiverName }}</strong>
        		</p>
        		<p>Valor:
        			<strong>{{ pixReturn?.value | currency:'BRL':'symbol':'1.2-2' }}</strong>
        		</p>
        	</div>
        	<div class="qr-code">
        		<gipi-qr-code #QRCodeRef [qrdata]="pixReturn?.qrCode" [width]="200"></gipi-qr-code>
        	</div>
        	<p class="payment-deadline" *ngIf="pixReturn?.pixType === 'DYNAMIC'">Prazo para pagamento
        		<br>
        			<strong>{{ expirationDate }}</strong>
        		</p>
        	</div>