import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { NumberUtil, ObjectUtil, PageDTO, StringUtil } from '@gipisistemas/ng-core';
import { FinancialCostCenter } from '../models/cost-center.model';
import { FinancialCostCenterFilterDTO } from '../models/dto/cost-center-filter.dto';

@Injectable({ providedIn: 'root' })
export class FinancialCostCenterService extends BaseCrudService<FinancialCostCenter, FinancialCostCenterFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.costCenter, http, authenticationService);
    }

    validate(entity: FinancialCostCenter): void {
        super.validate(entity);
        if (!NumberUtil.isPositive(entity.code) || StringUtil.isEmpty(entity.description)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }

    getNextCode(upperCost: FinancialCostCenter): Observable<number> {
        return this.http.get(this.url(`next-code?upperCategoryId=${ObjectUtil.isNewModel(upperCost) ? 0 : upperCost.id}`), this.options()).pipe(
            map(this.mapper),
            takeUntil(this.onDestroy),
            catchError(this.handleError)
        );
    }

    findByValue(value: string, page: number, size?: number, sort?: { property: string; direction: 'asc' | 'desc'; }, onlyChildren?: boolean): Observable<PageDTO<FinancialCostCenter>> {
        const validatedValue: string = value.trim();
        const validatedPage: number = Math.max(page, 0);
        const validatedSize: number = Math.max(size, 10);
        const validatedOnlyChildren: boolean = ((onlyChildren !== null) && (onlyChildren !== undefined)) ? onlyChildren : true;

        const params: URLSearchParams = new URLSearchParams();
        params.set('value', validatedValue.toString());
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());
        params.set('onlyChildren', validatedOnlyChildren.toString());

        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.property)) {
            params.set('sort', `${sort.property},${sort.direction}`);
        }

        const queryString: string = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';

        return this.http.get(this.url(`find-by-value${queryString}`), this.options()).pipe(
            map(this.mapper),
            takeUntil(this.onDestroy),
            catchError(this.handleError),
        );
    }

}
